import React, { useState } from "react";
import { Button, TextField, Box, Grid2 } from "@mui/material";

const EmailActions = ({
    handleUpdateEmail,
    handleResendEmail,
    refetchDetails,
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const handleEditClick = () => {
        setIsEditing(!isEditing);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setEmailError(""); // Clear the error message when the user starts typing
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleEmailSubmit = async () => {
        if (!validateEmail(email)) {
            setEmailError("Por favor, ingrese un correo electrónico válido.");
            return;
        }
        try {
            await handleUpdateEmail(email);
            refetchDetails();
            setIsEditing(false);
        } catch {
            setEmailError("Error al actualizar el correo.");
        }
    };

    const handleResendClick = async () => {
        try {
            await handleResendEmail(email);
        } catch {
            setEmailError("Error al reenviar el correo.");
        }
    };

    return (
        <Box>
            <Box display="flex" gap={2} mt={2}>
                <Button variant="contained" onClick={handleResendClick}>
                    Reenviar Correo
                </Button>
                <Button variant="contained" onClick={handleEditClick}>
                    {isEditing ? "Cancelar Edición" : "Cambiar Correo"}
                </Button>
            </Box>
            <Grid2 direction={"row"}>
                {isEditing && (
                    <Box mt={2}>
                        <TextField
                            label="Nuevo Correo"
                            value={email}
                            onChange={handleEmailChange}
                            fullWidth
                            error={emailError}
                            helperText={emailError}
                        />
                        <Button
                            variant="contained"
                            onClick={handleEmailSubmit}
                            sx={{ mt: 2 }}
                        >
                            Guardar
                        </Button>
                    </Box>
                )}
            </Grid2>
        </Box>
    );
};

export default EmailActions;
