import React from "react";
import {
    Button,
    CircularProgress,
    Switch,
    FormControlLabel,
    Grid2 as Grid,
} from "@mui/material";
import StoreDetails from "./StoreDetails";
import StoreContact from "./StoreContact";
import { Schedule } from "./schedule/Schedule";
import { StoreImageUpload } from "./StoreImageUpload";
import StoreAddress from "./StoreAddress";
import StoreMap from "utils/StoreMap";
import { getFlexEndStyle, loadingProgressStyle } from "../style/style";

const StoreFormContent = ({
    initialValues,
    register,
    handleSubmit,
    watch,
    errors,
    setFile,
    setOpeningHours,
    setValue,
    trigger,
    openingHours,
    loading,
    handleSearchStore,
    omvs,
    is_registered,
    storeSearched,
    setStoreSearched,
    handleFormSubmit,
    handleZipcodeSearch,
    responseZipCode,
    handleOmvChange,
    selectedOmv,
    toggleState,
    handleToggleChange,
    dataRowEdit,
    setdataRowEdit,
    municipality,
    neighborhood,
    fetchNeighborhoodLocation,
    responseNeighborhoodLocation,
    cleanMunicipality,
    cleanNeighborhood,
    handleLocationChange,
    handleStateSelect,
    handleMunicipalitySelect,
    handleNeighborhoodSelect,
    mapLocation,
    statesData,
}) => {
    const responseMunicipality = municipality?.payload?.municipalities;
    const responseNeighborhood = neighborhood?.payload?.neighborhoods;

    const renderAddValidation =
        initialValues ||
        (is_registered !== true &&
            is_registered !== undefined &&
            storeSearched);
    return (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid sx={{ width: !renderAddValidation ? "35vw" : "70vw" }}>
                <Grid
                    sx={{ display: "flex", direction: "row", flexWrap: "wrap" }}
                >
                    <Grid
                        sx={{
                            width: { xs: "70vw", md: "35vw" },
                            paddingX: 2,
                            paddingBottom: 5,
                            maxHeight: { md: "70vh", xs: "auto" },
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        {!initialValues && (
                            <StoreDetails
                                handleOmvChange={handleOmvChange}
                                selectedOmv={selectedOmv}
                                loading={loading}
                                omvs={omvs}
                                register={register}
                                watch={watch}
                                errors={errors}
                                handleSearchStore={() => {
                                    handleSearchStore();
                                    setStoreSearched(true);
                                }}
                                setStoreSearched={setStoreSearched}
                            />
                        )}
                        {renderAddValidation && (
                            <>
                                <div style={getFlexEndStyle()}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={toggleState}
                                                onChange={handleToggleChange}
                                                color="primary"
                                            />
                                        }
                                        label="Tienda Publicada"
                                    />
                                </div>
                                <StoreContact
                                    register={register}
                                    errors={errors}
                                    dataRowEdit={dataRowEdit}
                                />
                                <Schedule
                                    setValue={setValue}
                                    setOpeningHours={setOpeningHours}
                                    openingHours={openingHours}
                                    dataRowEdit={dataRowEdit}
                                    setdataRowEdit={setdataRowEdit}
                                    errors={errors}
                                    register={register}
                                />
                                <StoreImageUpload
                                    trigger={trigger}
                                    errors={errors}
                                    setFile={setFile}
                                    setValue={setValue}
                                    dataRowEdit={dataRowEdit}
                                    toggleState={toggleState}
                                />
                                <StoreAddress
                                    responseNeighborhoodLocation={
                                        responseNeighborhoodLocation
                                    }
                                    fetchNeighborhoodLocation={
                                        fetchNeighborhoodLocation
                                    }
                                    cleanNeighborhood={cleanNeighborhood}
                                    cleanMunicipality={cleanMunicipality}
                                    statesData={statesData}
                                    setValue={setValue}
                                    loading={loading}
                                    responseZipCode={responseZipCode}
                                    handleZipcodeSearch={handleZipcodeSearch}
                                    register={register}
                                    errors={errors}
                                    dataRowEdit={dataRowEdit}
                                    handleLocationChange={handleLocationChange}
                                    handleStateSelect={handleStateSelect}
                                    handleMunicipalitySelect={
                                        handleMunicipalitySelect
                                    }
                                    handleNeighborhoodSelect={
                                        handleNeighborhoodSelect
                                    }
                                    municipalities={responseMunicipality || []}
                                    ResponseNeighborhood={
                                        responseNeighborhood || []
                                    }
                                />
                            </>
                        )}
                    </Grid>
                    <Grid
                        sx={{
                            display: renderAddValidation ? "grid" : "none",
                            width: { xs: "70vw", md: "35vw" },
                            paddingX: 2,
                            paddingBottom: 5,
                        }}
                    >
                        <StoreMap
                            dataRowEdit={dataRowEdit}
                            latitude={mapLocation.latitude}
                            longitude={mapLocation.longitude}
                            onLocationChange={handleLocationChange}
                        />
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    style={{ display: renderAddValidation ? "block" : "none" }}
                >
                    {initialValues ? "Editar tienda" : "Registrar tienda"}
                </Button>
                {loading && (
                    <div style={loadingProgressStyle()}>
                        <CircularProgress />
                    </div>
                )}
            </Grid>
        </form>
    );
};
export default StoreFormContent;
