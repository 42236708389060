import axiosTokenInstance from "services/api/backoffice";
import { EDIT_STORES, EDIT_STORES_ERROR } from "_models/redux/types";

// Acción para editar una tienda
export const editStoresAction =
    (formData, selectedRowStore) => async (dispatch) => {
        try {
            const response = await axiosTokenInstance.put(
                `/api/ipb/v2/directory-stores/${selectedRowStore}/edit`,
                formData
            );

            const { data, status } = response || {};

            dispatch({
                type: EDIT_STORES,
                payload: data || {},
                status: status || {},
                method: "put",
            });
        } catch (e) {
            dispatch({
                type: EDIT_STORES_ERROR,
                payload: e || {},
            });
        }
    };
