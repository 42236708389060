// Imports necesarios
import { Grid, Button } from "@mui/material";
import React from "react";
const PreventionSection = ({
    type,
    handleOpenPopUp,
    orderDetails,
    fraudStyle,
    chargebackStyle,
    title,
}) => {
    console.log("PreventionSection -> orderDetails", orderDetails);
    const userPermissions = localStorage.getItem("user_permissions");

    const hasPermissions = userPermissions.includes("CHARGEBACK");
    return (
        <>
            {hasPermissions && (
                <Grid container className="device-info">
                    <Grid item xs={12}>
                        {title}
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        style={{ marginBottom: "20px" }}
                    >
                        <Grid item xs={6}>
                            <div>Fraude</div>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            {orderDetails?.is_fraud ? (
                                <p style={fraudStyle}>Reportado</p>
                            ) : (
                                <Button
                                    onClick={() =>
                                        handleOpenPopUp(
                                            "fraud",
                                            orderDetails.id
                                        )
                                    }
                                    style={{
                                        backgroundColor: "#ede7f6",
                                        color: "#6a1b9a",
                                        textTransform: "none",
                                        width: "100px",
                                    }}
                                >
                                    Reportar
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    {type !== "detailsOpenPay" && (
                        <Grid
                            container
                            alignItems="center"
                            style={{ marginBottom: "20px" }}
                        >
                            <Grid item xs={6}>
                                <div>Contracargo</div>
                            </Grid>
                            <Grid item xs={6} style={{ textAlign: "right" }}>
                                {orderDetails?.is_chargeback ? (
                                    <p style={chargebackStyle}>Reportado</p>
                                ) : (
                                    <Button
                                        onClick={() =>
                                            handleOpenPopUp(
                                                "chargeback",
                                                orderDetails.id
                                            )
                                        }
                                        style={{
                                            backgroundColor: "#ede7f6",
                                            color: "#6a1b9a",
                                            textTransform: "none",
                                            width: "100px",
                                        }}
                                    >
                                        Reportar
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            )}
        </>
    );
};

export default PreventionSection;
