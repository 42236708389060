import { useEffect, useState } from "react";

export const useStoreDetails = ({ watch }) => {
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);

    useEffect(() => {
        const subscription = watch((value) => {
            if (
                value.omv &&
                value.establishmentId &&
                (value.omv !== 283 || value.mtcId)
            ) {
                setIsSearchDisabled(false);
            } else {
                setIsSearchDisabled(true);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    return {
        isSearchDisabled,
        setIsSearchDisabled,
    };
};
