import { Box, Typography } from "@mui/material";
import DataTable from "widgets/datagrid/DataTable";

const DataTableComponent = ({
    loading,
    mappedRows,
    Columns,
    pagination,
    page,
    size,
}) => (
    <div style={{ height: 400, width: "100%", marginTop: "10px" }}>
        {mappedRows?.length > 0 ? (
            <Box sx={{ height: "100%", maxWidth: "100%" }}>
                <DataTable
                    rows={mappedRows}
                    columns={Columns}
                    page={page}
                    pageSize={size}
                    rowCount={pagination?.total_rows ?? 0}
                    onPageChange={pagination?.handleChangePage}
                    handleChangePageSize={pagination?.handleChangePageSize}
                    loading={loading}
                />
            </Box>
        ) : (
            <Typography variant="h6" style={{ textAlign: "center" }}>
                No hay registros disponibless
            </Typography>
        )}
    </div>
);

export default DataTableComponent;
