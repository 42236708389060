import { Grid, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { getEsimQr } from "_models/redux/esim-qr/action";
import { municipalityAction } from "_models/redux/sepomex-municipality/action";
import { neighborhoodAction } from "_models/redux/sepomex-neighborhood/action";
import { getSaleOrderDetailAction } from "_models/redux/sales-detail-order/action";
import { cleanSaleOrderDetailAction } from "_models/redux/sales-detail-order/clean-action";
import { cleanChangeSimToEsimAction } from "_models/redux/change-sim-esim/clean-action";
import { cleanChangeEsimToSimAction } from "_models/redux/change-esim-sim/clean-action";
import { changeSimToEsimAction } from "_models/redux/change-sim-esim/action";
import { changeEsimToSimAction } from "_models/redux/change-esim-sim/action";
import { simActivationAction } from "_models/redux/sim-activation/action";
import { postTransactionsChargeback } from "_models/redux/detail-transaction-chargeback/action";
import { postTransactionsFraud } from "_models/redux/detail-transaction-fraud/action";
import { cleanTransactionChargeback } from "_models/redux/detail-transaction-chargeback/clean_action";
import { cleanTransactionFraud } from "_models/redux/detail-transaction-fraud/clean_action";
import { getSaleOrdersAction } from "_models/redux/sales-orders/action";
import OrderComponentDialogContent from "./componentsDetails/OrderComponentDialogContent";
import TransactionComponentDialogContent from "./componentsDetails/TransactionComponentDialogContent";

const DataTableDetailOrder = (props) => {
    const {
        response,
        handleOpenPopUp,
        type,
        onClose,
        namePayment,
        setIdReverso,
        idTransaction,
    } = props || {};
    const isLoading = !response;
    const hasPaymentInfo = response?.payment_info !== null;
    return (
        <>
            {isLoading && <CircularProgress />}
            <Grid
                container
                spacing={4}
                className="device-info-container m-b-15"
            >
                {!isLoading && (
                    <>
                        {hasPaymentInfo ? (
                            <>
                                {type === "detailsSTP" ||
                                type === "detailsNetPay" ||
                                type === "detailsOpenPay" ? (
                                    <TransactionComponentDialogContent
                                        response={response}
                                        handleOpenPopUp={handleOpenPopUp}
                                        type={type}
                                        onClose={onClose}
                                        namePayment={namePayment}
                                        setIdReverso={setIdReverso}
                                        idTransaction={idTransaction}
                                    />
                                ) : (
                                    <>
                                        <OrderComponentDialogContent
                                            response={response}
                                            handleOpenPopUp={handleOpenPopUp}
                                            type={type}
                                            onClose={onClose}
                                            namePayment={namePayment}
                                            setIdReverso={setIdReverso}
                                            idTransaction={idTransaction}
                                        />
                                    </>
                                )}
                            </>
                        ) : (
                            <Typography variant="h6" color="textSecondary">
                                No hay información disponible.
                            </Typography>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
};

const mapStateToProps = (state) => ({
    saleOrderDetail: state?.saleOrderDetail,
    saleOrderEsimQr: state?.saleOrderEsimQr,
    municipality: state?.municipality,
    neighborhood: state?.neighborhood,
    changeEsimToSim: state?.changeEsimToSim,
    changeSimToEsim: state?.changeSimToEsim,
    simActivation: state?.simActivation,
    detailTransactionFraud: state?.detailTransactionFraud,
    detailTransactionChargeback: state?.detailTransactionChargeback,
});
const mapDispatchToProps = {
    getEsimQr,
    municipalityAction,
    neighborhoodAction,
    getSaleOrderDetailAction,
    cleanSaleOrderDetailAction,
    cleanChangeSimToEsimAction,
    cleanChangeEsimToSimAction,
    changeSimToEsimAction,
    changeEsimToSimAction,
    simActivationAction,
    postTransactionsChargeback,
    postTransactionsFraud,
    cleanTransactionChargeback,
    cleanTransactionFraud,
    getSaleOrdersAction,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DataTableDetailOrder);
