import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { getScrollbarStyles } from "utils/scrollbarStyles";

const DataTable = ({
    rows,
    columns,
    onRowClick,
    page,
    pageSize,
    rowCount,
    onPageChange,
    handleChangePageSize,
    isActive,
    loading,
}) => {
    const [paginationModel, setPaginationModel] = React.useState({
        page: page === 0 ? 0 : page - 1,
        pageSize: pageSize,
    });

    const handlePaginationModelChange = (newPaginationModel) => {
        if (paginationModel.pageSize !== newPaginationModel.pageSize) {
            newPaginationModel.page = 0;
            setPaginationModel(newPaginationModel);
            handleChangePageSize(newPaginationModel.pageSize);
        } else if (paginationModel.page !== newPaginationModel.page) {
            setPaginationModel(newPaginationModel);
            onPageChange(newPaginationModel.page + 1);
        }
    };

    const handleGridRowClick = (params) => {
        if (onRowClick) {
            onRowClick(params.id);
        }
    };

    const getRowClassName = (params) => {
        if (params.row.is_chargeback && params.row.is_fraud) {
            return "chargeback-and-fraud-row";
        } else if (params.row.is_chargeback) {
            return "chargeback-row";
        } else if (params.row.is_fraud) {
            return "fraud-row";
        }
        return "";
    };

    const getRowClassNameActive = (params) => {
        if (!params.row.active) {
            return "fraud-row";
        }
        return "";
    };

    return (
        <Box
            sx={{
                height: "100%",
                maxWidth: "100%",
                ...getScrollbarStyles(),
            }}
        >
            <DataGrid
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={[10, 25, 50, 100]}
                rows={rows}
                columns={columns}
                rowCount={rowCount}
                loading={loading}
                disableColumnMenu
                onRowClick={handleGridRowClick}
                getRowClassName={
                    isActive === "isActive"
                        ? getRowClassNameActive
                        : getRowClassName
                }
            />
        </Box>
    );
};

export default DataTable;
