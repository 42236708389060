import axiosTokenInstance from "services/api/backoffice";
import {
    LIST_GIFT_SIM_IPB,
    LIST_GIFT_SIM_IPB_ERROR,
} from "_models/redux/types";

export const listGiftSimIpbAction =
    (page = 1, size = 10, filters = {}) =>
    async (dispatch) => {
        try {
            const params = { page, size, ...filters };
            const response = await axiosTokenInstance.get(
                `/api/backoffice/orders/gifts?`,
                { params }
            );

            const { data, status } = response || {};

            dispatch({
                type: LIST_GIFT_SIM_IPB,
                payload: data || {},
                status: status || {},
                method: "get",
            });
        } catch (e) {
            dispatch({
                type: LIST_GIFT_SIM_IPB_ERROR,
                payload: e || {},
            });
        }
    };
