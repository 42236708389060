import React, { useState, useEffect } from "react";
import { Stack, Typography, Button, Box } from "@mui/material";
import { FileUpload } from "@mui/icons-material";

export const StoreImageUpload = ({
    setValue,
    trigger,
    errors,
    setFile,
    dataRowEdit,
    toggleState,
}) => {
    const [nameImage, setNameImage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [imageUrl, setImageUrl] = useState("");

    // Tipos MIME permitidos
    const allowedMimeTypes = ["image/jpeg", "image/png"];

    useEffect(() => {
        if (dataRowEdit?.media_url) {
            setImageUrl(dataRowEdit.media_url);
            setNameImage(dataRowEdit.media_url.split("/").pop());
        } else if (toggleState) {
            setErrorMessage("La imagen de la tienda es obligatoria");
        }
    }, [dataRowEdit, toggleState]);

    const handleFileChange = (event) => {
        const file = event.target.files?.[0]; // Verifica si hay un archivo seleccionado
        if (file) {
            // Validar el tipo MIME
            if (!allowedMimeTypes.includes(file.type)) {
                setErrorMessage("La extensión del archivo no está soportada.");
                resetImage();
                return;
            }

            // Procesar archivo válido
            setErrorMessage("");
            setNameImage(file.name);
            setValue("image", file);
            setFile(file);
            trigger("image");

            // Leer archivo para obtener una vista previa y convertir a base64
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageUrl(e.target.result);
                setValue("imageBase64", e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            resetImage();
        }
    };

    const resetImage = () => {
        setValue("image", null);
        setValue("imageBase64", null);
        setNameImage("");
        setImageUrl("");
        trigger("image");
    };

    return (
        <>
            {/* Vista previa de la imagen */}
            {imageUrl && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Box
                        component="img"
                        src={imageUrl}
                        alt="Vista previa"
                        sx={{
                            width: 150,
                            height: 150,
                            borderRadius: 2,
                            objectFit: "cover",
                            border: "2px solid #BC955C",
                        }}
                    />
                </Box>
            )}

            {/* Botón para subir imagen */}
            <Stack direction={"column"}>
                <Button
                    variant="contained"
                    component="label"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "5px",
                        height: "94px",
                        background:
                            "transparent linear-gradient(180deg, #BC955C87 0%, #B18E5BCF 100%) 0% 0% no-repeat padding-box",
                        cursor: "pointer",
                        spacing: "15px",
                    }}
                >
                    <FileUpload fontSize="large" />
                    <Typography variant="body2" color="white">
                        Subir imagen de la tienda
                    </Typography>
                    <input
                        type="file"
                        accept="image/*"
                        hidden
                        name="image"
                        onChange={handleFileChange}
                    />
                </Button>

                {/* Nombre de la imagen */}
                {nameImage && (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            textAlign="center"
                            sx={{ wordBreak: "break-word", maxWidth: 300 }}
                        >
                            {nameImage}
                        </Typography>
                    </Box>
                )}

                {/* Mensajes de error */}
                {errorMessage && (
                    <Typography variant="body2" color="error" fontSize={12}>
                        {errorMessage}
                    </Typography>
                )}
                {errors?.storeImage?.message && (
                    <Typography variant="body2" color="error" fontSize={12}>
                        {errors.storeImage.message}
                    </Typography>
                )}
            </Stack>
        </>
    );
};
