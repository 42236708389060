import React from "react";

//React redux:
import { connect } from "react-redux";

//Material UI:
import { Box, Grid, Typography } from "@mui/material";

//Actions:
import { atnPortabilityAction } from "_models/redux/atn-portability/action";
import { cleanAtnPortabilityAction } from "_models/redux/atn-portability/clean-action";

//Hooks:
import { usePortabilityPageHook } from "./hooks/usePortabilityPageHook";

//Components:
import DataTable from "widgets/datagrid/DataTable";
import SectionFilterComponent from "./components/SectionFilterComponent";

function PortabilityPageComponent({
    atnPortabilityAction,
    cleanAtnPortabilityAction,
    atnPortability,
}) {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        errorMessage,
    } = usePortabilityPageHook({
        atnPortabilityAction,
        cleanAtnPortabilityAction,
        atnPortability,
    });
    return (
        <Box>
            <h4> Portabilidades </h4>
            <Box sx={{ mb: 10 }}>
                <SectionFilterComponent
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Box>

            {mappedRows?.length > 0 ? (
                <Grid item xs={12} sx={{ width: "calc(100vw - 280px)" }}>
                    <DataTable
                        rows={mappedRows}
                        columns={Columns}
                        onRowClick={handleRowClick}
                        page={page}
                        pageSize={size}
                        rowCount={pagination?.total_rows ?? 0}
                        onPageChange={pagination?.handleChangePage}
                        handleChangePageSize={pagination?.handleChangePageSize}
                        loading={loading}
                    />
                </Grid>
            ) : (
                <Typography variant="h6">
                    No hay registros disponibles
                </Typography>
            )}

            {/* <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            /> */}
        </Box>
    );
}

const mapStateToProps = (state) => ({
    atnPortability: state?.atnPortability,
});

const mapDispatchToProps = {
    atnPortabilityAction,
    cleanAtnPortabilityAction,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PortabilityPageComponent);
