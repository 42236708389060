import React, { useEffect, useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "appStyles";

import RouterComponent from "router/component";

import { Grid2 as Grid } from "@mui/material";

import TestButtonBanner from "widgets/testBannerButton/TestBannerButton.jsx";

import {
    AppContext,
    SessionContext,
    LoadersContext,
} from "_models/context/app";

import Loader from "widgets/loader/component";

function App() {
    /*** aplication values ***/
    const [appUser, setAppUser] = useState();
    const appValues = { appUser, setAppUser };

    /*** session values ***/
    const [sessionTicket, setSessionTicket] = useState();
    const [typeServiceSelect, setTypeServiceSelect] = useState("");

    const sessionValues = {
        sessionTicket,
        setSessionTicket,
        typeServiceSelect,
        setTypeServiceSelect,
    };

    const [isLoading, setIsLoading] = useState(false);
    const [isScreenLoading, setIsScreenLoading] = useState(false);

    const loadersValue = {
        isLoading,
        setIsLoading,
        isScreenLoading,
        setIsScreenLoading,
    };

    const appEnv = process.env.REACT_APP_ENV;

    const [noSectionCls, setSectionCls] = useState("app-main-layout");

    useEffect(() => {
        if (appEnv !== "PROD") {
            setSectionCls("app-main-layout no-prod-section");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <LoadersContext.Provider value={loadersValue}>
                <AppContext.Provider value={appValues}>
                    <SessionContext.Provider value={sessionValues}>
                        <div className="app">
                            {appEnv !== "PROD" ? (
                                <TestButtonBanner appEnv={appEnv} />
                            ) : null}
                            <Grid>
                                <header className="app-main-header"></header>

                                <section className={noSectionCls}>
                                    <RouterComponent />
                                </section>
                                <footer className="app-main-footer"></footer>
                            </Grid>
                        </div>
                    </SessionContext.Provider>
                </AppContext.Provider>
                <Loader loading={isScreenLoading} mainScreenLoader={true} />
            </LoadersContext.Provider>
        </ThemeProvider>
    );
}

export default App;
