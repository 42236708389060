import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";

const GiftSimOrderDetailContent = ({ orderDetails }) => {
    if (!orderDetails) {
        return (
            <Typography variant="body2">No hay detalles disponibles</Typography>
        );
    }

    const renderDetail = (label, value) => (
        <Typography variant="body2">
            <strong>{label}:</strong>{" "}
            <span style={{ color: "#6c757d" }}>{value ?? "N/A"}</span>
        </Typography>
    );

    return (
        <Grid container spacing={5} className="device-info-container m-b-15">
            <Grid item xs={12} md={6} direction={"column"}>
                <Typography
                    variant="h6"
                    paddingBottom={2}
                    sx={{ color: "#4C2484" }}
                >
                    Detalle de Orden
                </Typography>
                <Card>
                    <CardContent>
                        {renderDetail("ID de Tienda", orderDetails.distributor)}
                        {renderDetail("Lead ID", orderDetails.lead_id)}
                        {renderDetail("ID del Pedido", orderDetails.order_id)}
                        {renderDetail(
                            "Fecha de activación",
                            orderDetails.order_created !== null
                                ? convertToMexicoTime(
                                      orderDetails.order_created
                                  )
                                : "N/A"
                        )}
                        {renderDetail(
                            "Número transitorio",
                            orderDetails.mobile_subscriber_number
                        )}
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} md={6} direction={"column"}>
                <Typography
                    variant="h6"
                    paddingBottom={2}
                    sx={{ color: "#4C2484" }}
                >
                    Detalles del Regalo
                </Typography>
                <Card>
                    <CardContent>
                        {renderDetail(
                            "Código de regalo",
                            orderDetails.pre_register_number
                        )}
                        {renderDetail(
                            "Redimido",
                            orderDetails.redimed ? "Sí" : "No"
                        )}
                        {renderDetail("IMEI", orderDetails.imei)}
                        {renderDetail(
                            "Nombre Completo",
                            orderDetails.full_name
                        )}
                        {renderDetail(
                            "Teléfono del Regalo",
                            orderDetails.gift_phone_number
                        )}
                        {renderDetail("Correo", orderDetails.gift_email)}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default GiftSimOrderDetailContent;
