import {
    CLEAN_DETAIL_LIST_GIFT_SIM_IPB,
    CLEAN_DETAIL_LIST_GIFT_SIM_IPB_ERROR,
} from "_models/redux/types";

export const cleanDetailGiftSimIpbAction = () => async (dispatch) => {
    try {
        dispatch({
            payload: undefined,
            type: CLEAN_DETAIL_LIST_GIFT_SIM_IPB,
        });
    } catch {
        dispatch({
            payload: undefined,
            type: CLEAN_DETAIL_LIST_GIFT_SIM_IPB_ERROR,
        });
    }
};
