import React from "react";
import { connect } from "react-redux";
import FiltroSTP from "./list-transactions-stp/components/FiltroSTP";
import { listSTPAction } from "_models/redux/list-transfer-stp/action";
import {
    Grid,
    Typography,
    DialogActions,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    Button,
} from "@mui/material";
import { PageSTPHook } from "./list-transactions-stp/hooks/PageSTPHook";
import CircularProgress from "@mui/material/CircularProgress";
import DataTable from "widgets/datagrid/DataTable";
import CustomDialog from "widgets/component/CustomDialog";
import { detailSTPAction } from "_models/redux/detail-transactions-stp/action";
import { cleanTransferSTPClean } from "_models/redux/list-transfer-stp/clean-action";
import DataTableDetailOrder from "widgets/customDialogComponentDetails/ComponentDialog";
import { cleanDetailSTPAction } from "_models/redux/detail-transactions-stp/clean-action";
import { actionReportTransactionsFraud } from "_models/redux/report-transaction-fraud/action";
import { actionReportTransactionsChargeback } from "_models/redux/report-transaction-chargeback/action";
import { cleanReportPrepaidChargebackDetails } from "_models/redux/report-transaction-chargeback/clean_action";
import { cleanReportTransactionsFraud } from "_models/redux/report-transaction-fraud/clean_action";

const PageSTP = ({
    listSTPAction,
    cleanTransferSTPClean,
    listTransactionsSTP,
    detailSTPAction,
    cleanDetailSTPAction,
    detailsTransactionsSTP,
    reportTransactionsFraud,
    reportTransactionsChargeback,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    transactionId,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        openActionFraud,
        handleCloseFraudChargeback,
        setDescriptionTransaction,
        setError,
        error,
        loadingFraudAndChargeback,
        isError,
        handleConfirm,
        setOpenActionFraud,
        handleOpenPopUp,
    } = PageSTPHook({
        listSTPAction,
        cleanTransferSTPClean,
        listTransactionsSTP,
        detailSTPAction,
        cleanDetailSTPAction,
        detailsTransactionsSTP,
        reportTransactionsFraud,
        reportTransactionsChargeback,
        cleanReportPrepaidChargebackDetails,
        actionReportTransactionsChargeback,
        actionReportTransactionsFraud,
        cleanReportTransactionsFraud,
        transactionId,
        onClose,
    });

    return (
        <>
            <Grid>
                <h4> Transacciones de STP </h4>
            </Grid>
            <Grid marginBottom={4}>
                <FiltroSTP
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Grid>
            <Grid>
                {mappedRows?.length > 0 ? (
                    <Grid paddingRight={5} paddingLeft={0}>
                        <DataTable
                            rows={mappedRows}
                            columns={Columns}
                            onRowClick={handleRowClick}
                            page={page}
                            pageSize={size}
                            rowCount={pagination?.total_rows ?? 0}
                            onPageChange={pagination?.handleChangePage}
                            handleChangePageSize={
                                pagination?.handleChangePageSize
                            }
                            loading={loading}
                        />
                    </Grid>
                ) : (
                    <Typography variant="h6">
                        No hay registros disponibles
                    </Typography>
                )}
            </Grid>

            <CustomDialog
                fullWidth
                title={
                    detailsTransactionsSTP?.response?.payment_info === null
                        ? "No hay datos"
                        : "Transacción STP"
                }
                content={
                    <DataTableDetailOrder
                        type="detailsSTP"
                        handleOpenPopUp={handleOpenPopUp}
                        setOpenActionFraud={setOpenActionFraud}
                        response={detailsTransactionsSTP?.response}
                        idTransaction={
                            detailsTransactionsSTP?.response?.transaction_type
                                ?.transaction_id
                        }
                        onClose={handleClose}
                    />
                }
                open={isDialogOpenDetails}
                onClose={handleClose}
                maxWidth="md"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
            <Dialog open={openActionFraud} onClose={handleCloseFraudChargeback}>
                <DialogTitle>
                    {"¿Estás seguro de que quieres ejecutar esta acción?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            "Una vez que ejecutes esta acción, no podrás deshacerla."
                        }
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Descripción"
                        type="text"
                        fullWidth
                        onChange={(event) => {
                            setDescriptionTransaction(event.target.value);
                            setError(event.target.value.length < 5);
                        }}
                        error={error}
                        helperText={
                            error
                                ? "Este campo requiere al menos 5 caracteres"
                                : ""
                        }
                    />
                    {loadingFraudAndChargeback && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}
                    {dialogMessage && (
                        <DialogContentText
                            style={{
                                color: isError ? "red" : "green",
                            }}
                        >
                            {dialogMessage}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseFraudChargeback}
                        color="primary"
                    >
                        Cerrar
                    </Button>

                    {reportTransactionsFraud?.status !== 200 &&
                        reportTransactionsChargeback?.status !== 200 && (
                            <Button
                                onClick={handleConfirm}
                                color="primary"
                                autoFocus
                                disabled={error}
                            >
                                Aceptar
                            </Button>
                        )}
                </DialogActions>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => ({
    listTransactionsSTP: state?.listTransactionsSTP,
    detailsTransactionsSTP: state?.detailsTransactionsSTP,
    reportTransactionsFraud: state?.reportTransactionsFraud,
    reportTransactionsChargeback: state?.reportTransactionsChargeback,
});

const mapDispatchToProps = {
    listSTPAction,
    cleanTransferSTPClean,
    detailSTPAction,
    cleanDetailSTPAction,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageSTP);
