import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Button } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import { useLocation, useNavigate } from "react-router-dom";

export const useGiftSimListIpb = ({
    listGiftSimIpbAction,
    listGiftSimIpb,
    cleanListGiftSimIpbClean,
    detailGiftSimIpbAction,
    detailGiftSimIpb,
    cleanDetailGiftSimIpbAction,
    updateEmailAction,
    resendEmailAction,
    transactionId,
    onClose,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { search } = location;
    const queryParams = new URLSearchParams(search);

    const { payload } = listGiftSimIpb || {};
    const { response } = detailGiftSimIpb || {};
    const { row: rows, pagination } = payload || {};

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();
    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState("");
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [currentIdDetail, setCurrentIdDetail] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [action, setAction] = useState(null);
    const [error, setError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        if (transactionId) {
            setIsDialogOpenDetails(true);
            detailGiftSimIpbAction(transactionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionId]);

    useEffect(() => {
        if (response?.response?.status > 200) {
            setDialogMessage("Error");
            setDialogMessage(response?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        if (listGiftSimIpb?.status === 200) {
            setLoading(false);
        } else if (listGiftSimIpb?.payload?.response?.status > 200) {
            setDialogMessage(listGiftSimIpb?.payload?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [listGiftSimIpb]);

    useEffect(() => {
        const initialFilters = Object.fromEntries(queryParams.entries());
        setSearchParams(initialFilters);

        Object.keys(initialFilters).forEach((key) => {
            setValue(key, initialFilters[key]);
        });

        const initialPage = initialFilters.page
            ? parseInt(initialFilters.page, 10)
            : 0;
        const initialSize = initialFilters.size
            ? parseInt(initialFilters.size, 10)
            : 50;

        setPage(initialPage);
        setSize(initialSize);

        if (Object.keys(initialFilters).length > 0) {
            setLoading(true);
            listGiftSimIpbAction(page, size, initialFilters)
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const onSubmit = (data) => {
        const { from_date, to_date } = data;

        if (new Date(from_date) > new Date(to_date)) {
            setErrorMessage(
                "La fecha de inicio no puede ser mayor a la fecha final"
            );
            return;
        }
        /* eslint-disable no-unused-vars */
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(
                ([_, value]) => value !== undefined && value !== ""
            )
        );
        /* eslint-enable no-unused-vars */

        const queryString = new URLSearchParams(filteredData).toString();
        navigate(`/sales/gift-sim-ipb?${queryString}`);

        setLoading(true);
        setSearchParams(filteredData);
        setIsSubmitted(true);
        setErrorMessage("");
    };

    useEffect(() => {
        if (isSubmitted || page !== 0 || size !== 50) {
            /* eslint-disable no-unused-vars */
            const filteredSearchParams = Object.fromEntries(
                Object.entries(searchParams).filter(
                    ([_, value]) => value !== undefined && value !== ""
                )
                /* eslint-enable no-unused-vars */
            );

            setLoading(true);
            listGiftSimIpbAction(page, size, filteredSearchParams)
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    console.error("listGiftSimIpbAction error:", error);
                    setLoading(false);
                });
        }
        return () => {
            cleanListGiftSimIpbClean();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchParams,
        listGiftSimIpbAction,
        cleanListGiftSimIpbClean,
        isSubmitted,
        error,
    ]);

    useEffect(() => {
        if (page === 0) {
            return;
        } else if (isSubmitted || page !== 1 || size !== 50) {
            /* eslint-disable no-unused-vars */
            const filteredSearchParams = Object.fromEntries(
                Object.entries(searchParams).filter(
                    ([_, value]) => value !== undefined && value !== ""
                )
                /* eslint-enable no-unused-vars */
            );

            setLoading(true);
            listGiftSimIpbAction(page, size, filteredSearchParams)
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    console.error("listGiftSimIpbAction error:", error);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, size]);

    const handleChangePage = (newPage) => {
        setPage(newPage);

        const queryString = new URLSearchParams({
            ...searchParams,
            page: newPage,
        }).toString();
        navigate(`/sales/gift-sim-ipb?${queryString}`);
    };

    const handleChangePageSize = (newSize) => {
        setSize(newSize);
        setPage(1);

        const queryString = new URLSearchParams({
            ...searchParams,
            size: newSize,
        }).toString();
        navigate(`/sales/gift-sim-ipb?${queryString}`);
    };

    useEffect(() => {
        if (transactionId) {
            const filteredSearchParams = Object.fromEntries(
                Object.entries(searchParams).filter(
                    // eslint-disable-next-line no-unused-vars
                    ([_, value]) => value !== undefined && value !== ""
                )
            );
            setIsSubmitted(true);
            setLoading(true);
            setIsDialogOpenDetails(true);
            detailGiftSimIpbAction(transactionId);
            listGiftSimIpbAction(page, size, filteredSearchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionId]);

    const mappedRows =
        rows?.map((row) => ({
            ...row,
            id: `${row.id}`,
        })) || [];

    const handleIdButtonClick = (id) => {
        if (id) {
            setIsDialogOpenDetails(true);
            setCurrentIdDetail(id);
            detailGiftSimIpbAction(id)
                .then(() => setLoading(false))
                .catch(() => {
                    setDialogMessage("Error");
                    setDialogMessage(
                        "Error al cargar los detalles de la transferencia."
                    );
                    setIsDialogOpen(true);
                    setLoading(false);
                });

            return () => {
                cleanDetailGiftSimIpbAction();
            };
        }
    };

    const handleClose = () => {
        if (isDialogOpenDetails && isDialogOpen) {
            setIsDialogOpen(false);
        } else {
            setIsDialogOpen(false);
            setIsDialogOpenDetails(false);
            cleanDetailGiftSimIpbAction();
            if (transactionId) {
                onClose();
            }
        }
    };

    const handleOpenPopUp = () => {
        setAction(action);
    };

    const refetchDetails = () => {
        if (currentIdDetail) {
            console.log("Refetching details");
            detailGiftSimIpbAction(currentIdDetail);
        }
    };

    const handleUpdateEmail = async (email) => {
        if (currentIdDetail) {
            try {
                await updateEmailAction(currentIdDetail, email);
                setDialogMessage("Correo actualizado exitosamente!");
                setIsDialogOpen(true);
            } catch {
                setDialogMessage("Error al actualizar el correo");
                setIsDialogOpen(true);
            }
        }
    };

    const handleResendEmail = async () => {
        if (currentIdDetail) {
            try {
                await resendEmailAction(currentIdDetail);
                setDialogMessage("Correo reenviado exitosamente!");
                setIsDialogOpen(true);
            } catch {
                setDialogMessage("Error al reenviar el correo");
                setIsDialogOpen(true);
            }
        }
    };

    const Columns = [
        {
            field: "id",
            headerName: "Detalles",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    onClick={() => handleIdButtonClick(params.row.id)}
                    sx={{
                        "&:hover": {
                            color: "white",
                        },
                    }}
                >
                    {"Ver detalle"}
                </Button>
            ),
        },

        {
            field: "created",
            headerName: "Fecha de registro",
            width: 250,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => {
                return convertToMexicoTime(params);
            },
        },

        {
            field: "full_name",
            headerName: "Nombre completo",
            width: 220,
            headerAlign: "center",
            align: "center",
        },

        {
            field: "phone",
            headerName: "Teléfono",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
    ];

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        rows,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        setErrorMessage,
        setError,
        error,
        handleOpenPopUp,
        refetchDetails,
        handleUpdateEmail,
        handleResendEmail,
    };
};
