import React from "react";
import {
    SupportAgent as SupportAgentIcon,
    LocalActivity as LocalActivityIcon,
    ConfirmationNumberOutlined as ConfirmationNumberOutlinedIcon,
    FormatListBulleted as FormatListBulletedIcon,
    Redeem as RedeemIcon,
    MobileScreenShare as MobileScreenShareIcon,
} from "@mui/icons-material";
import GenericCollapse from "./GenericCollapse";
import GenericNavListItem from "./GenericNavListItem";
import GenericListItemButton from "./MenuItemWithSubmenu";
import MenuCheckByDirectNumberWidget from "../../widgets/menu_check_by_direct_number_widget";

const SupportTicketsMenu = ({
    userPermissions,
    openMenuSupport,
    handleClickSupport,
    activeStyle,
    props,
}) => {
    return (
        <>
            {(userPermissions?.includes("SUPPORT_TICKETS_ACTIONS") ||
                userPermissions?.includes("SUPPORT_TICKETS_SUPERVISOR")) && (
                <>
                    <GenericListItemButton
                        onClick={handleClickSupport}
                        className={"layout-menu-submenu"}
                        title="ATN"
                        IconComponent={SupportAgentIcon}
                        openMenu={openMenuSupport}
                    />
                    <GenericCollapse
                        className={"layout-menu-submenu-options"}
                        openMenu={openMenuSupport}
                        userPermissions={userPermissions}
                    >
                        {userPermissions?.includes(
                            "SUPPORT_TICKETS_ACTIONS"
                        ) && (
                            <MenuCheckByDirectNumberWidget
                                parentProps={props}
                                userPermissions={userPermissions}
                            />
                        )}
                        <GenericNavListItem
                            userPermissions={userPermissions}
                            permission="SUPPORT_TICKETS_ACTIONS"
                            to="/my-tickets"
                            title="Mis incidencias"
                            IconComponent={LocalActivityIcon}
                            activeStyle={activeStyle}
                        />
                        <GenericNavListItem
                            userPermissions={userPermissions}
                            permission="SUPPORT_TICKETS_SUPERVISOR"
                            to="/tickets"
                            title="Incidencias"
                            IconComponent={ConfirmationNumberOutlinedIcon}
                            activeStyle={activeStyle}
                        />
                        <GenericNavListItem
                            userPermissions={userPermissions}
                            permission="SUPPORT_TICKETS_ACTIONS"
                            to="/sales/orders"
                            title="Órdenes de compra"
                            IconComponent={FormatListBulletedIcon}
                            activeStyle={activeStyle}
                        />
                        <GenericNavListItem
                            userPermissions={userPermissions}
                            permission="SUPPORT_TICKETS_ACTIONS"
                            to="/list/ipb"
                            title="Compras IPB"
                            IconComponent={FormatListBulletedIcon}
                            activeStyle={activeStyle}
                        />
                        <GenericNavListItem
                            userPermissions={userPermissions}
                            permission="SUPPORT_TICKETS_ACTIONS"
                            to="/sales/gift-orders"
                            title="Órdenes de regalo"
                            IconComponent={RedeemIcon}
                            activeStyle={activeStyle}
                        />
                        <GenericNavListItem
                            userPermissions={userPermissions}
                            permission="SUPPORT_TICKETS_SUPERVISOR"
                            to="/sales/gift-sim-ipb"
                            title="Regalo SIM IPB"
                            IconComponent={RedeemIcon}
                            activeStyle={activeStyle}
                        />
                        <GenericNavListItem
                            userPermissions={userPermissions}
                            permission="SUPPORT_TICKETS_ACTIONS"
                            to="/portabilidades"
                            title="Portabilidades"
                            IconComponent={MobileScreenShareIcon}
                            activeStyle={activeStyle}
                        />
                    </GenericCollapse>
                </>
            )}
        </>
    );
};

export default SupportTicketsMenu;
