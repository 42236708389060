import React from "react";
import { connect } from "react-redux";
import FiltroListIpb from "./components/FiltroListIpb";
import { Grid, Typography } from "@mui/material";
import { useListSimIpb } from "./hooks/useListSimIpb";
import DataTable from "widgets/datagrid/DataTable";
import CustomDialog from "widgets/component/CustomDialog";
import DataTableDetailOrder from "widgets/customDialogComponentDetails/ComponentDialog";
import { listSimIpbAction } from "_models/redux/list-ipb/action";
import { cleanListSimIpbClean } from "_models/redux/list-ipb/clean-action";
import { detailListSimIpbAction } from "_models/redux/detail-list-sim-ipb/action";
import { cleanDetailListSimIpbAction } from "_models/redux/detail-list-sim-ipb/clean-action";

const PageListSimIpb = ({
    listSimIpbAction,
    listSimIpb,
    cleanListSimIpbClean,
    detailListSimIpbAction,
    detailListSimIpb,
    cleanDetailListSimIpbAction,
    transactionId,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        getBackgroundColor,
        handleOpenPopUp,
    } = useListSimIpb({
        listSimIpbAction,
        listSimIpb,
        cleanListSimIpbClean,
        detailListSimIpbAction,
        detailListSimIpb,
        cleanDetailListSimIpbAction,
        transactionId,
        onClose,
    });

    return (
        <>
            <Grid>
                <h4>Compras IPB </h4>
            </Grid>
            <Grid marginBottom={4}>
                <FiltroListIpb
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Grid>
            <Grid>
                {mappedRows?.length > 0 ? (
                    <Grid paddingRight={5} paddingLeft={0}>
                        <DataTable
                            rows={mappedRows}
                            columns={Columns}
                            onRowClick={handleRowClick}
                            page={page}
                            pageSize={size}
                            rowCount={pagination?.total_rows ?? 0}
                            onPageChange={pagination?.handleChangePage}
                            handleChangePageSize={
                                pagination?.handleChangePageSize
                            }
                            getBackgroundColor={getBackgroundColor}
                            mappedRows={mappedRows}
                            loading={loading}
                        />
                    </Grid>
                ) : (
                    <Typography variant="h6">
                        No hay registros disponibles
                    </Typography>
                )}
            </Grid>

            <CustomDialog
                fullWidth
                title={
                    detailListSimIpb?.response?.payment_info === null
                        ? "No hay datos"
                        : "Detalle SIM IPB"
                }
                content={
                    <DataTableDetailOrder
                        type="detailsSimIpb"
                        handleOpenPopUp={handleOpenPopUp}
                        response={detailListSimIpb?.response}
                        idTransaction={
                            detailListSimIpb?.response?.provider_type
                                ?.transaction_id
                        }
                        namePayment={
                            detailListSimIpb?.response?.provider_type?.name
                        }
                        onClose={handleClose}
                    />
                }
                open={isDialogOpenDetails}
                onClose={handleClose}
                maxWidth="md"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    listSimIpb: state?.listSimIpb,
    detailListSimIpb: state?.detailListSimIpb,
});

const mapDispatchToProps = {
    listSimIpbAction,
    cleanListSimIpbClean,
    detailListSimIpbAction,
    cleanDetailListSimIpbAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageListSimIpb);
