import { useEffect, useState } from "react";

export const useSelect = (dataRowEdit, data, handleSelect) => {
    const [selected, setSelected] = useState(false);

    useEffect(() => {
        if (dataRowEdit && !selected) {
            const selectedItem = data.find((item) => item.name === dataRowEdit);
            if (selectedItem) {
                handleSelect(selectedItem.id);
                setSelected(true);
            }
        }
    }, [dataRowEdit, data, handleSelect, selected]);

    return [selected, setSelected];
};
