import React from "react";
import { connect } from "react-redux";
import FiltroGiftSimListIpb from "./components/FiltroGiftSimListIpb";
import { Grid2 as Grid, Typography } from "@mui/material";
import { useGiftSimListIpb } from "./hooks/useGiftSimListIpb";
import DataTable from "widgets/datagrid/DataTable";
import CustomDialog from "widgets/component/CustomDialog";
import GiftSimOrderDetailContent from "./components/GiftSimOrderDetailContent";
import EmailActions from "./components/EmailActions";
import { listGiftSimIpbAction } from "_models/redux/gift-sim-list-ipb/action";
import { cleanListGiftSimIpbClean } from "_models/redux/gift-sim-list-ipb/clean-action";
import { detailGiftSimIpbAction } from "_models/redux/detail-gift-sim-ipb/action";
import { cleanDetailGiftSimIpbAction } from "_models/redux/detail-gift-sim-ipb/clean-action";
import {
    updateEmailAction,
    resendEmailAction,
} from "_models/redux/email-sim-gift-ipb/action";

const PageGiftSimListIpb = ({
    listGiftSimIpbAction,
    listGiftSimIpb,
    cleanListGiftSimIpbClean,
    detailGiftSimIpbAction,
    detailGiftSimIpb,
    cleanDetailGiftSimIpbAction,
    updateEmailAction,
    resendEmailAction,
    transactionId,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        getBackgroundColor,
        refetchDetails,
        handleUpdateEmail,
        handleResendEmail,
    } = useGiftSimListIpb({
        listGiftSimIpbAction,
        listGiftSimIpb,
        cleanListGiftSimIpbClean,
        detailGiftSimIpbAction,
        detailGiftSimIpb,
        cleanDetailGiftSimIpbAction,
        updateEmailAction,
        resendEmailAction,
        transactionId,
        onClose,
    });

    return (
        <>
            <Grid>
                <h4>Regalo SIM IPB</h4>
            </Grid>
            <Grid marginBottom={4}>
                <FiltroGiftSimListIpb
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Grid>
            <Grid>
                <>
                    {mappedRows?.length > 0 ? (
                        <Grid paddingRight={5} paddingLeft={0}>
                            <DataTable
                                rows={mappedRows}
                                columns={Columns}
                                onRowClick={handleRowClick}
                                page={page}
                                pageSize={size}
                                rowCount={pagination?.total_rows ?? 0}
                                onPageChange={pagination?.handleChangePage}
                                handleChangePageSize={
                                    pagination?.handleChangePageSize
                                }
                                getBackgroundColor={getBackgroundColor}
                                mappedRows={mappedRows}
                                loading={loading}
                            />
                        </Grid>
                    ) : (
                        <Typography variant="h6">
                            No hay registros disponibles
                        </Typography>
                    )}
                </>
            </Grid>

            <CustomDialog
                fullWidth
                title={
                    detailGiftSimIpb?.response?.payment_info === null
                        ? "No hay datos"
                        : "Detalle Regalo SIM IPB"
                }
                content={
                    <>
                        <GiftSimOrderDetailContent
                            orderDetails={
                                detailGiftSimIpb?.response?.orders_details
                            }
                        />
                        <EmailActions
                            handleUpdateEmail={handleUpdateEmail}
                            refetchDetails={refetchDetails}
                            handleResendEmail={handleResendEmail}
                        />
                    </>
                }
                open={isDialogOpenDetails}
                onClose={handleClose}
                maxWidth="md"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    listGiftSimIpb: state?.listGiftSimIpb,
    detailGiftSimIpb: state?.detailGiftSimIpb,
});

const mapDispatchToProps = {
    listGiftSimIpbAction,
    cleanListGiftSimIpbClean,
    detailGiftSimIpbAction,
    cleanDetailGiftSimIpbAction,
    updateEmailAction,
    resendEmailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageGiftSimListIpb);
