import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";

import { datagridStyles } from "modules/support-gift-orders/styles/giftOrdersStyles";

import { useGiftOrdersList } from "../../hooks/gift-orders-list/useGiftOrdersList";

import { GiftOrdersDatagridContext } from "../../data/giftOrdersContexts";

const GiftOrdersList = () => {
    // Hook propio de la funcionalidad del listado (datagrid)
    const {
        rows,
        rowCount,
        columns,

        datagridPage,
        datagridPageSize,

        loadingDatagrid,

        changePage,
        changePageSize,

        giftOrdersDatagridValues,
    } = useGiftOrdersList();

    const [paginationModel, setPaginationModel] = React.useState({
        page: datagridPage === 0 ? 0 : datagridPage - 1,
        pageSize: datagridPageSize,
    });

    useEffect(() => {
        setPaginationModel({
            page: datagridPage === 0 ? 0 : datagridPage - 1,
            pageSize: datagridPageSize,
        });
    }, [datagridPage, datagridPageSize]);

    const handlePaginationModelChange = (newPaginationModel) => {
        if (paginationModel.pageSize !== newPaginationModel.pageSize) {
            newPaginationModel.page = 0;
            setPaginationModel(newPaginationModel);
            changePageSize(newPaginationModel.pageSize);
        } else if (paginationModel.page !== newPaginationModel.page) {
            setPaginationModel(newPaginationModel);
            changePage(newPaginationModel.page + 1);
        }
    };

    return (
        <GiftOrdersDatagridContext.Provider value={giftOrdersDatagridValues}>
            <div
                style={{ height: "auto", width: "100%" }}
                className={"leads-list"}
            >
                <DataGrid
                    pagination
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    pageSizeOptions={[10, 25, 50, 100]}
                    rows={rows}
                    columns={columns}
                    rowCount={rowCount}
                    loading={loadingDatagrid}
                    disableColumnMenu
                    disableSelectionOnClick={true}
                    checkboxSelection={false}
                    sx={datagridStyles}
                />
            </div>
        </GiftOrdersDatagridContext.Provider>
    );
};

export default GiftOrdersList;
