import * as React from "react";
import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { datagridStyles } from "../style/datagridStyle";
import CustomNoRowsOverlay from "widgets/datagrid/CustomNoRowsOverlay";
import CustomLinearProgress from "widgets/datagrid/CustomLinearProgress";

const DataGridComponent = ({
    columns,
    rows,
    rowCount,
    datagridPage,
    datagridPageSize,
    loadingDatagrid,
    changeOrderPage,
    getRowClassName,
}) => {
    const [paginationModel, setPaginationModel] = React.useState({
        page: datagridPage === 0 ? 0 : datagridPage - 1,
        pageSize: datagridPageSize,
    });

    const handlePaginationModelChange = (newPaginationModel) => {
        if (paginationModel.pageSize !== newPaginationModel.pageSize) {
            newPaginationModel.page = 0;
        }
        setPaginationModel(newPaginationModel);
        changeOrderPage(
            newPaginationModel.page + 1,
            newPaginationModel.pageSize
        );
    };

    return (
        <Box sx={{ height: "100%", maxWidth: "100%" }}>
            {rowCount === 0 ? (
                <div style={{ fontSize: "18px", paddingTop: "20px" }}>
                    No hay registros disponibles
                </div>
            ) : (
                <DataGrid
                    getRowClassName={getRowClassName}
                    columns={columns}
                    rows={rows}
                    autoHeight={true}
                    pagination
                    rowCount={rowCount}
                    paginationMode="server"
                    paginationModel={paginationModel}
                    onPaginationModelChange={handlePaginationModelChange}
                    pageSizeOptions={[10, 25, 50, 100]}
                    loading={loadingDatagrid}
                    disableColumnMenu
                    disableSelectionOnClick={true}
                    checkboxSelection={false}
                    sx={datagridStyles}
                    components={{
                        NoRowsOverlay: CustomNoRowsOverlay,
                        NoResultsOverlay: CustomNoRowsOverlay,
                        LoadingOverlay: CustomLinearProgress,
                    }}
                />
            )}
        </Box>
    );
};

export default DataGridComponent;
