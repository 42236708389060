import React from "react";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import DataGridComponent from "../components/DataGridComponent";
import FilterForm from "../components/FilterForm";
import CustomDialogComponent from "../components/CustomDialogComponentDetail";
import { DataGridHook } from "../hooks/dataGridHook";
import { getSaleOrdersAction } from "_models/redux/sales-orders/action";
import { cleanSaleOrdersAction } from "_models/redux/sales-orders/clean-action";
import { getSaleOrderDetailAction } from "_models/redux/sales-detail-order/action";
import { cleanSaleOrderDetailAction } from "_models/redux/sales-detail-order/clean-action";

const OrdersDatagrid = ({
    getSaleOrdersAction,
    saleOrders,
    cleanSaleOrdersAction,
    getSaleOrderDetailAction,
    cleanSaleOrderDetailAction,
    saleOrderDetail,
    transactionId,
    onClose,
}) => {
    const {
        columns,
        rows,
        datagridPageSize,
        rowCount,
        datagridPage,
        loadingDatagrid,
        modalOpenDetailOrder,
        inputChange,
        onSubmit,
        registerOptions,
        PopModalError,
        handleSubmit,
        dateValue,
        setDateValue,
        register,
        errors,
        changeOrderPage,
        getRowClassName,
        handleClose,
    } = DataGridHook({
        getSaleOrdersAction,
        saleOrders,
        cleanSaleOrdersAction,
        getSaleOrderDetailAction,
        cleanSaleOrderDetailAction,
        saleOrderDetail,
        transactionId,
        onClose,
    });

    return (
        <Grid container>
            <Grid item xs={12}>
                <FilterForm
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    dateValue={dateValue}
                    setDateValue={setDateValue}
                    register={register}
                    registerOptions={registerOptions}
                    inputChange={inputChange}
                    errors={errors}
                />
            </Grid>
            <Grid item xs={12} sx={{ width: "calc(100vw - 280px)" }}>
                <DataGridComponent
                    columns={columns}
                    rows={rows}
                    datagridPageSize={datagridPageSize}
                    rowCount={rowCount}
                    datagridPage={datagridPage}
                    loadingDatagrid={loadingDatagrid}
                    changeOrderPage={changeOrderPage}
                    getRowClassName={getRowClassName}
                />
            </Grid>
            <PopModalError />
            <CustomDialogComponent
                modalOpenDetailOrder={modalOpenDetailOrder}
                saleOrderDetail={saleOrderDetail}
                handleClose={handleClose}
            />
        </Grid>
    );
};

const mapStateToProps = (state) => ({
    saleOrders: state?.saleOrders,
    saleOrderDetail: state?.saleOrderDetail,
});

const mapDispatchToProps = {
    getSaleOrdersAction,
    cleanSaleOrdersAction,
    getSaleOrderDetailAction,
    cleanSaleOrderDetailAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersDatagrid);
