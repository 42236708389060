import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import Store from "./_models/redux/store";

import App from "App";

import "assets/css/styles.css";

//Sentry:
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_ENV === "PROD") {
    // Sentry.init({
    //     dsn: "https://ff795fc2d9e58b2ef584dc160bb3da92@o4505885589962752.ingest.sentry.io/4506599730184192",
    //     integrations: [
    //         new Sentry.BrowserTracing({
    //             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //             tracePropagationTargets: [
    //                 "localhost",
    //                 /^https:\/\/yourserver\.io\/api/,
    //             ],
    //         }),
    //         new Sentry.Replay({
    //             maskAllText: false,
    //             blockAllMedia: false,
    //         }),
    //     ],
    //     // Performance Monitoring
    //     tracesSampleRate: 1.0, //  Capture 100% of the transactions
    //     // Session Replay
    //     replaysSessionSampleRate: 0.01, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // });
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // NOTE: Prevent double render
    // <React.StrictMode>
    <Provider store={Store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
    // </React.StrictMode>
);
