import React from "react";
import { Typography } from "@mui/material";

const ActionLabels = ({ isFraud, isChargeback }) => {
    if (!isFraud && !isChargeback) {
        return (
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    height: "100%",
                    paddingLeft: "16px",
                }}
            >
                <Typography variant="body2">N/A</Typography>
            </div>
        );
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                gap: "4px",
                alignItems: "flex-start",
                justifyContent: "center",
                height: "100%",
                paddingLeft: "16px",
            }}
        >
            {isFraud && (
                <div
                    style={{
                        backgroundColor: "#FED2D2",
                        color: "#C13131",
                        padding: "2px 6px",
                        fontSize: "12px",
                        borderRadius: "20px",
                        textAlign: "center",
                    }}
                >
                    Fraude
                </div>
            )}
            {isChargeback && (
                <div
                    style={{
                        backgroundColor: "#FFDDCF",
                        color: "#E27406",
                        padding: "2px 6px",
                        fontSize: "12px",
                        borderRadius: "20px",
                        textAlign: "center",
                    }}
                >
                    Contracargo
                </div>
            )}
        </div>
    );
};
export default ActionLabels;
