import axiosTokenInstance from "services/api/backoffice";
import { LEADS, LEADS_ERROR } from "_models/redux/types";

export const getLeads = (params) => async (dispatch) => {
    let queryStringParams = "";

    const { size } = params || {};

    queryStringParams = size ? "?size=" + size : "";

    /*** ***/

    const { page } = params || {};

    const pageNumber = !isNaN(parseInt(page)) ? page : undefined;

    if (queryStringParams === "") {
        queryStringParams += pageNumber ? "?page=" + pageNumber : "";
    } else {
        queryStringParams += pageNumber ? "&page=" + pageNumber : "";
    }

    /*** ***/

    const { contact_name } = params || {};

    if (queryStringParams === "") {
        queryStringParams += contact_name
            ? "?contact_name=" + contact_name
            : "";
    } else {
        queryStringParams += contact_name
            ? "&contact_name=" + contact_name
            : "";
    }

    /*** ***/

    const { contact_phone } = params || {};

    if (queryStringParams === "") {
        queryStringParams += contact_phone
            ? "?contact_phone=" + contact_phone
            : "";
    } else {
        queryStringParams += contact_phone
            ? "&contact_phone=" + contact_phone
            : "";
    }

    /*** ***/

    const { contact_email } = params || {};

    if (queryStringParams === "") {
        queryStringParams += contact_email
            ? "?contact_email=" + contact_email
            : "";
    } else {
        queryStringParams += contact_email
            ? "&contact_email=" + contact_email
            : "";
    }

    /*** ***/

    const { date } = params || {};

    if (queryStringParams === "") {
        queryStringParams += date ? "?date=" + date : "";
    } else {
        queryStringParams += date ? "&date=" + date : "";
    }

    /*** ***/

    try {
        const response = await axiosTokenInstance.get(
            `/api/callcenter/leads${queryStringParams}`
        );

        const { data, status } = response || {};

        dispatch({
            type: LEADS,
            payload: data || {},
            status: status || null,
            method: "get",
        });
    } catch (e) {
        dispatch({
            type: LEADS_ERROR,
            payload: e || {},
            status: e?.response?.status || null,
            method: "get",
        });
    }
};
