import {
    Box,
    Button,
    IconButton,
    Modal,
    Stack,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { SelectSchedule } from "./SelectSchedule";
import { modalStyle, butttonStyle } from "modules/directory-ipb/style/style";
import {
    scheduleRowStyle,
    deleteAllHoursStyle,
} from "modules/directory-ipb/style/scheduleStyles";
import { useSchedule } from "modules/directory-ipb/hooks/useSchedule";

const daysOfWeek = [
    "Lunes",
    "Martes",
    "Miércoles",
    "Jueves",
    "Viernes",
    "Sábado",
    "Domingo",
];

const ScheduleRow = ({ dayName, text, onDelete }) => (
    <Stack direction="row" alignItems="center" sx={scheduleRowStyle.stack}>
        <Typography variant="p" fontSize={12} sx={scheduleRowStyle.typography}>
            {`${dayName}: ${text}`}
        </Typography>
        <IconButton
            edge="end"
            aria-label="delete"
            size="small"
            onClick={onDelete}
            sx={scheduleRowStyle.iconButton}
        >
            <DeleteIcon fontSize="small" />
        </IconButton>
    </Stack>
);

export const Schedule = ({
    setOpeningHours,
    openingHours,
    dataRowEdit,
    setValue,
}) => {
    const {
        open,
        confirmOpen,
        handleOpen,
        handleClose,
        handleDeleteClick,
        handleConfirmDelete,
        handleDeleteAllHours,
        handleAddHour,
        disabledDay,
        setConfirmOpen,
    } = useSchedule({
        setOpeningHours,
        openingHours,
        dataRowEdit,
        setValue,
    });
    return (
        <Stack>
            <Typography variant="p" fontSize={12} fontWeight={600}>
                Horario de apertura y cierre
            </Typography>
            <Stack direction="column" spacing={1}>
                {openingHours.map(
                    ({ week_day, opening_time, closing_time, is_24_hours }) => {
                        const dayName = daysOfWeek[week_day - 1];
                        const text = is_24_hours
                            ? "Abierto las 24 horas"
                            : `${opening_time} - ${closing_time}`;
                        return (
                            <ScheduleRow
                                key={week_day}
                                dayName={dayName}
                                text={text}
                                onDelete={() => handleDeleteClick(week_day)}
                            />
                        );
                    }
                )}

                {openingHours.length > 0 && (
                    <Typography
                        variant="p"
                        fontSize={12}
                        sx={deleteAllHoursStyle}
                        onClick={handleDeleteAllHours}
                    >
                        Eliminar todos los horarios
                    </Typography>
                )}
            </Stack>
            <Button
                sx={{
                    ...butttonStyle,
                    width: "100%",
                    borderRadius: "24px",
                    my: 2,
                }}
                onClick={handleOpen}
                disabled={openingHours.length === 7}
                type="button"
            >
                Seleccionar horarios
            </Button>

            {openingHours.length === 0 && (
                <Typography
                    variant="body2"
                    color="error"
                    fontSize={12}
                    marginBottom={2}
                >
                    Debes agregar al menos un horario
                </Typography>
            )}

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <SelectSchedule
                        disabledDay={disabledDay}
                        onCanceled={handleClose}
                        onSave={handleAddHour}
                    />
                </Box>
            </Modal>

            <Dialog open={confirmOpen} onClose={() => setConfirmOpen(false)}>
                <DialogTitle>Confirmar eliminación</DialogTitle>
                <DialogContent>
                    ¿Estás seguro de que deseas eliminar este horario?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)}>
                        Cancelar
                    </Button>
                    <Button color="error" onClick={handleConfirmDelete}>
                        Eliminar
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};
