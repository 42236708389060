import React from "react";
import Dialogs from "./Dialogs";
import statesData from "../../../utils/cat_sepomex_states.json";
import { useMapLocation } from "../hooks/useMapLocation";
import StoreFormContent from "./StoreFormContent";

const AddStoreForm = ({
    initialValues,
    register,
    handleSubmit,
    watch,
    errors,
    setFile,
    setOpeningHours,
    setValue,
    trigger,
    openingHours,
    loading,
    responseMessage,
    handleSearchStore,
    omvs,
    is_registered,
    dialogOpen,
    setDialogOpen,
    successDialogOpen,
    setSuccessDialogOpen,
    storeSearched,
    setStoreSearched,
    handleFormSubmit,
    handleZipcodeSearch,
    responseZipCode,
    handleOmvChange,
    selectedOmv,
    warningsDialogOpen,
    setWarningsDialogOpen,
    toggleState,
    handleToggleChange,
    dataRowEdit,
    setdataRowEdit,
    cleanIsEditStores,
    handleClose,
    cleanIsRegisteredStores,
    municipalityAction,
    neighborhoodAction,
    municipality,
    neighborhood,
    fetchNeighborhoodLocation,
    responseNeighborhoodLocation,
    cleanMunicipality,
    cleanNeighborhood,
    handleLocationChange,
}) => {
    const responseMunicipality = municipality?.payload?.municipalities;
    const responseNeighborhood = neighborhood?.payload?.neighborhoods;
    const {
        mapLocation,
        handleStateSelect,
        handleMunicipalitySelect,
        handleNeighborhoodSelect,
    } = useMapLocation({
        responseZipCode,
        responseMunicipality,
        statesData,
        municipalityAction,
        neighborhoodAction,
        responseNeighborhoodLocation,
        onLocationChange: handleLocationChange,
    });

    return (
        <>
            <StoreFormContent
                initialValues={initialValues}
                register={register}
                handleSubmit={handleSubmit}
                watch={watch}
                errors={errors}
                setFile={setFile}
                setOpeningHours={setOpeningHours}
                setValue={setValue}
                trigger={trigger}
                openingHours={openingHours}
                loading={loading}
                handleSearchStore={handleSearchStore}
                omvs={omvs}
                is_registered={is_registered}
                storeSearched={storeSearched}
                setStoreSearched={setStoreSearched}
                handleFormSubmit={handleFormSubmit}
                handleZipcodeSearch={handleZipcodeSearch}
                responseZipCode={responseZipCode}
                handleOmvChange={handleOmvChange}
                selectedOmv={selectedOmv}
                toggleState={toggleState}
                handleToggleChange={handleToggleChange}
                dataRowEdit={dataRowEdit}
                setdataRowEdit={setdataRowEdit}
                municipality={municipality}
                neighborhood={neighborhood}
                fetchNeighborhoodLocation={fetchNeighborhoodLocation}
                responseNeighborhoodLocation={responseNeighborhoodLocation}
                cleanMunicipality={cleanMunicipality}
                cleanNeighborhood={cleanNeighborhood}
                handleLocationChange={handleLocationChange}
                handleStateSelect={handleStateSelect}
                handleMunicipalitySelect={handleMunicipalitySelect}
                handleNeighborhoodSelect={handleNeighborhoodSelect}
                mapLocation={mapLocation}
                statesData={statesData}
            />
            <Dialogs
                cleanIsRegisteredStores={cleanIsRegisteredStores}
                cleanIsEditStores={cleanIsEditStores}
                setWarningsDialogOpen={setWarningsDialogOpen}
                warningsDialogOpen={warningsDialogOpen}
                handleClose={handleClose}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                successDialogOpen={successDialogOpen}
                setSuccessDialogOpen={setSuccessDialogOpen}
                responseMessage={responseMessage}
            />
        </>
    );
};

export default AddStoreForm;
