import axiosTokenInstance from "services/api/backoffice";
import {
    REGISTERED_STORES,
    REGISTERED_STORES_ERROR,
} from "_models/redux/types";

// Acción para registrar una tienda
export const registeredStoresAction = (formData) => async (dispatch) => {
    try {
        // Realiza la solicitud con `formData` y usa `application/json`
        const response = await axiosTokenInstance.post(
            `/api/ipb/v2/directory-stores`,
            formData
        );

        const { data, status } = response || {};

        // Despachar los datos de la respuesta
        dispatch({
            type: REGISTERED_STORES,
            payload: data || {},
            status: status || {},
            method: "post",
        });
    } catch (e) {
        // Manejo de errores
        dispatch({
            type: REGISTERED_STORES_ERROR,
            payload: e || {},
        });
    }
};
