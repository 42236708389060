import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";

const StoreMap = ({ latitude, longitude, onLocationChange, dataRowEdit }) => {
    console.log("latitude", latitude);
    console.log("longitude", longitude);

    const defaultPosition = { lat: 23.634501, lng: -102.552784 };
    const [markerPosition, setMarkerPosition] = useState(defaultPosition);
    const [zoom, setZoom] = useState(5);

    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey,
    });

    useEffect(() => {
        if (latitude && longitude) {
            setMarkerPosition({ lat: latitude, lng: longitude });
            setZoom(14);
        } else if (dataRowEdit?.latitude && dataRowEdit?.longitude) {
            setMarkerPosition({
                lat: dataRowEdit.latitude,
                lng: dataRowEdit.longitude,
            });
            setZoom(14);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latitude, longitude]);

    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkerPosition({ lat: newLat, lng: newLng });
        onLocationChange(newLat, newLng);
    };

    const handleMapClick = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkerPosition({ lat: newLat, lng: newLng });
        onLocationChange(newLat, newLng);
        setZoom(14);
    };

    if (!isLoaded) {
        return <div>Cargando mapa...</div>;
    }

    if (loadError) {
        return (
            <div>No se pudo cargar el mapa. Por favor, intenta más tarde.</div>
        );
    }

    return (
        <GoogleMap
            mapContainerStyle={{ width: "100%", height: "400px" }}
            center={markerPosition}
            zoom={zoom}
            onClick={handleMapClick}
        >
            <Marker
                position={markerPosition}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
            />
        </GoogleMap>
    );
};

export default StoreMap;
