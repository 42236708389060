import React from "react";
import { TextField, Grid2 as Grid } from "@mui/material";
import GenericAddButton from "widgets/GenericAddButton/GenericAddButton";
import SearchIcon from "@mui/icons-material/Search";

const FiltroGiftSimListIpb = ({ handleSubmit, onSubmit, register, errors }) => {
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={1} alignItems="center">
                <Grid xs={12} xl={1.5}>
                    <TextField
                        fullWidth
                        label="Desde"
                        type="date"
                        {...register("from_date")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={Boolean(errors.from_date)}
                        helperText={errors.from_date?.message}
                    />
                </Grid>
                <Grid xs={12} xl={1.5}>
                    <TextField
                        fullWidth
                        label="Hasta"
                        type="date"
                        {...register("to_date")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={Boolean(errors.to_date)}
                        helperText={errors.to_date?.message}
                    />
                </Grid>

                <Grid xs={12} xl={2}>
                    <TextField
                        fullWidth
                        label="Nombre Completo"
                        {...register("name")}
                        slotProps={{
                            input: {
                                shrink: true,
                            },
                        }}
                        error={Boolean(errors.name)}
                        helperText={errors.name?.message}
                    />
                </Grid>

                <Grid xs={12} xl={2}>
                    <TextField
                        fullWidth
                        label="Correo de registro"
                        type="email"
                        {...register("email", {
                            pattern: {
                                value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                message: "Correo no es válido",
                            },
                        })}
                        slotProps={{
                            input: {
                                shrink: true,
                            },
                        }}
                        error={Boolean(errors.email)}
                        helperText={errors.email?.message}
                    />
                </Grid>

                <Grid xs={12} xl={2}>
                    <TextField
                        fullWidth
                        label="Número telefónico"
                        type="number"
                        {...register("phone_number")}
                        slotProps={{
                            input: {
                                shrink: true,
                            },
                        }}
                        error={Boolean(errors.phone)}
                        helperText={errors.phone?.message}
                    />
                </Grid>

                <Grid xs={12} xl={1}>
                    <GenericAddButton
                        buttonText="Buscar"
                        backgroundColor="#4C2484"
                        size="medium"
                        isSubmit
                        icon={<SearchIcon />}
                    />
                </Grid>
            </Grid>
        </form>
    );
};

export default FiltroGiftSimListIpb;
