import React from "react";
import { connect } from "react-redux";
import { Box, Typography, Grid } from "@mui/material";
import { useTransactionsOpenPayHook } from "./hooks/useTransactionsOpenPayHook";
import DataTable from "widgets/datagrid/DataTable";
import CustomDialog from "widgets/component/CustomDialog";
import FiltroOpenPay from "./components/FiltroOpenPay";
import { openPayAction } from "_models/redux/list-transactions-openpay/action";
import { cleanOpenPayAction } from "_models/redux/list-transactions-openpay/clean-action";
import { detailOpenPayAction } from "_models/redux/detail-transactions-openpay/action";
import { cleanDetailOpenPayAction } from "_models/redux/detail-transactions-openpay/clean-action";
import { actionReportTransactionsFraud } from "_models/redux/report-transaction-fraud/action";
import { cleanReportTransactionsFraud } from "_models/redux/report-transaction-fraud/clean_action";
import { cleanReportPrepaidChargebackDetails } from "_models/redux/report-transaction-chargeback/clean_action";
import { actionReportTransactionsChargeback } from "_models/redux/report-transaction-chargeback/action";
import FraudConfirmationDialog from "./components/FraudConfirmationDialog";
import TransactionDetailsDialog from "./components/TransactionDetailsDialog";

const PageTransactionsOpenPay = ({
    openPayAction,
    listOpenpay,
    cleanOpenPayAction,
    cleanDetailOpenPayAction,
    detailOpenPayAction,
    detailTransactionsOpenpay,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    reportTransactionsFraud,
    reportTransactionsChargeback,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
    transactionId,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        openActionFraud,
        setDescriptionTransaction,
        setError,
        error,
        handleConfirm,
        loadingFraudAndChargeback,
        isError,
        handleCloseFraudChargeback,
        setOpenActionFraud,
        handleOpenPopUp,
        setIdReverso,
        setActiveAction,
        setShouldReturnData,
        activeAction,
        shouldReturnData,
    } = useTransactionsOpenPayHook({
        openPayAction,
        listOpenpay,
        cleanOpenPayAction,
        detailOpenPayAction,
        cleanDetailOpenPayAction,
        detailTransactionsOpenpay,
        actionReportTransactionsFraud,
        cleanReportTransactionsFraud,
        reportTransactionsFraud,
        reportTransactionsChargeback,
        cleanReportPrepaidChargebackDetails,
        actionReportTransactionsChargeback,
        transactionId,
        onClose,
    });

    return (
        <Box>
            <Grid>
                <h4> Transacciones de OpenPay</h4>
            </Grid>
            <Box sx={{ mb: 10 }}>
                <FiltroOpenPay
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Box>

            {mappedRows?.length > 0 ? (
                <DataTable
                    rows={mappedRows}
                    columns={Columns}
                    onRowClick={handleRowClick}
                    page={page}
                    pageSize={size}
                    rowCount={pagination?.total_rows ?? 0}
                    onPageChange={pagination?.handleChangePage}
                    handleChangePageSize={pagination?.handleChangePageSize}
                    loading={loading}
                />
            ) : (
                <Typography variant="h6">
                    No hay registros disponibles
                </Typography>
            )}

            <TransactionDetailsDialog
                open={isDialogOpenDetails}
                onClose={handleClose}
                loading={loading}
                detailTransactionsOpenpay={detailTransactionsOpenpay}
                handleOpenPopUp={handleOpenPopUp}
                setOpenActionFraud={setOpenActionFraud}
                setIdReverso={setIdReverso}
                setActiveAction={setActiveAction}
                setShouldReturnData={setShouldReturnData}
                activeAction={activeAction}
                shouldReturnData={shouldReturnData}
            />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />

            <FraudConfirmationDialog
                open={openActionFraud}
                onClose={handleCloseFraudChargeback}
                setDescriptionTransaction={setDescriptionTransaction}
                setError={setError}
                error={error}
                handleConfirm={handleConfirm}
                loadingFraudAndChargeback={loadingFraudAndChargeback}
                dialogMessage={dialogMessage}
                isError={isError}
            />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    listOpenpay: state?.listOpenpay,
    detailTransactionsOpenpay: state?.detailTransactionsOpenpay,
    reportTransactionsFraud: state?.reportTransactionsFraud,
    reportTransactionsChargeback: state?.reportTransactionsChargeback,
});

const mapDispatchToProps = {
    openPayAction,
    cleanOpenPayAction,
    cleanDetailOpenPayAction,
    detailOpenPayAction,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageTransactionsOpenPay);
