import React from "react";
import { connect } from "react-redux";
import FiltroToku from "./list-transactions-toku/components/FiltroToku";
import { listTokuAction } from "_models/redux/list-transactions-toku/action";
import {
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    TextField,
    DialogActions,
    Button,
} from "@mui/material";
import { PageTokuHook } from "./list-transactions-toku/hooks/PageTokuHook";
import CircularProgress from "@mui/material/CircularProgress";
import { CircularProgressStyle } from "./list-transactions-toku/style/style";
import DataTable from "widgets/datagrid/DataTable";
import CustomDialog from "widgets/component/CustomDialog";
import { detailTransactionTokuAction } from "_models/redux/detail-transactions-toku/action";
import { cleanTransactionsTokuClean } from "_models/redux/list-transactions-toku/clean-action";
import DataTableDetailOrder from "widgets/customDialogComponentDetails/ComponentDialog";
import { cleanDetailTokuAction } from "_models/redux/detail-transactions-toku/clean-action";
import { actionReportTransactionsFraud } from "_models/redux/report-transaction-fraud/action";
import { cleanReportTransactionsFraud } from "_models/redux/report-transaction-fraud/clean_action";
import { cleanReportPrepaidChargebackDetails } from "_models/redux/report-transaction-chargeback/clean_action";
import { actionReportTransactionsChargeback } from "_models/redux/report-transaction-chargeback/action";

const PageToku = ({
    listTokuAction,
    cleanTransactionsTokuClean,
    listTransactionsToku,
    detailTransactionTokuAction,
    cleanDetailTokuAction,
    detailsTransactionsToku,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    reportTransactionsFraud,
    reportTransactionsChargeback,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
    transactionId,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        getBackgroundColor,
        openActionFraud,
        setDescriptionTransaction,
        setError,
        error,
        handleConfirm,
        loadingFraudAndChargeback,
        isError,
        handleCloseFraudChargeback,
        setOpenActionFraud,
        handleOpenPopUp,
        loadingDetail,
    } = PageTokuHook({
        listTokuAction,
        cleanTransactionsTokuClean,
        listTransactionsToku,
        detailTransactionTokuAction,
        cleanDetailTokuAction,
        detailsTransactionsToku,
        actionReportTransactionsFraud,
        cleanReportTransactionsFraud,
        reportTransactionsFraud,
        reportTransactionsChargeback,
        cleanReportPrepaidChargebackDetails,
        actionReportTransactionsChargeback,
        transactionId,
        onClose,
    });

    return (
        <>
            <Grid>
                <h4> Transacciones de Toku </h4>
            </Grid>
            <Grid marginBottom={4}>
                <FiltroToku
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Grid>
            <Grid>
                {mappedRows?.length > 0 ? (
                    <Grid paddingRight={5} paddingLeft={0}>
                        <DataTable
                            rows={mappedRows}
                            columns={Columns}
                            onRowClick={handleRowClick}
                            page={page}
                            pageSize={size}
                            rowCount={pagination?.total_rows ?? 0}
                            onPageChange={pagination?.handleChangePage}
                            handleChangePageSize={
                                pagination?.handleChangePageSize
                            }
                            getBackgroundColor={getBackgroundColor}
                            mappedRows={mappedRows}
                            loading={loading}
                        />
                    </Grid>
                ) : (
                    <Typography variant="h6">
                        No hay registros disponibles
                    </Typography>
                )}
            </Grid>

            <CustomDialog
                fullWidth
                title={
                    detailsTransactionsToku?.response?.payment_info === null
                        ? "No hay datos"
                        : "Transacción Toku"
                }
                content={
                    loadingDetail ? (
                        <CircularProgress
                            sx={CircularProgressStyle.CircularProgress}
                        />
                    ) : (
                        <DataTableDetailOrder
                            type="detailsToku"
                            handleOpenPopUp={handleOpenPopUp}
                            setOpenActionFraud={setOpenActionFraud}
                            response={detailsTransactionsToku?.response}
                            idTransaction={
                                detailsTransactionsToku?.response
                                    ?.transaction_type?.transaction_id
                            }
                            onClose={handleClose}
                        />
                    )
                }
                open={isDialogOpenDetails}
                onClose={handleClose}
                maxWidth="md"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
            <Dialog open={openActionFraud} onClose={handleCloseFraudChargeback}>
                <DialogTitle>
                    {"¿Estás seguro de que quieres ejecutar esta acción?"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            "Una vez que ejecutes esta acción, no podrás deshacerla."
                        }
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="description"
                        label="Descripción"
                        type="text"
                        fullWidth
                        onChange={(event) => {
                            setDescriptionTransaction(event.target.value);
                            setError(event.target.value.length < 5);
                        }}
                        error={error}
                        helperText={
                            error
                                ? "Este campo requiere al menos 5 caracteres"
                                : ""
                        }
                    />
                    {loadingFraudAndChargeback && (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </div>
                    )}
                    {dialogMessage && (
                        <DialogContentText
                            style={{
                                color: isError ? "red" : "green",
                            }}
                        >
                            {dialogMessage}
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleCloseFraudChargeback}
                        color="primary"
                    >
                        Cerrar
                    </Button>

                    {reportTransactionsFraud?.status !== 200 &&
                        reportTransactionsChargeback?.status !== 200 && (
                            <Button
                                onClick={handleConfirm}
                                color="primary"
                                autoFocus
                                disabled={error}
                            >
                                Aceptar
                            </Button>
                        )}
                </DialogActions>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state) => ({
    listTransactionsToku: state?.listTransactionsToku,
    detailsTransactionsToku: state?.detailsTransactionsToku,
    reportTransactionsFraud: state?.reportTransactionsFraud,
    reportTransactionsChargeback: state?.reportTransactionsChargeback,
});

const mapDispatchToProps = {
    listTokuAction,
    cleanTransactionsTokuClean,
    detailTransactionTokuAction,
    cleanDetailTokuAction,
    actionReportTransactionsFraud,
    cleanReportTransactionsFraud,
    cleanReportPrepaidChargebackDetails,
    actionReportTransactionsChargeback,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageToku);
