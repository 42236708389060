import React from "react";
import { connect } from "react-redux";

import { DataGrid } from "@mui/x-data-grid";

import { getLeads } from "_models/redux/leads/action";
import { cleanLeadsAction } from "_models/redux/leads/clean-action";
import { cleanLeadAssignmentAction } from "_models/redux/lead-assignment/clean_action";
import { setLeadAssignmentAction } from "_models/redux/lead-assignment/action";

import { useShippingDetailDatagrid } from "./_hook";

const ShippingDetailDatagridComponent = (props) => {
    const {
        columns,
        rows,
        datagridPageSize,
        rowCount,
        datagridPage,
        loadingDatagrid,
        changePage,
        changePageSize,
        datagridRegister,
        PopModalError,
    } = useShippingDetailDatagrid(props);

    const [paginationModel, setPaginationModel] = React.useState({
        page: datagridPage === 0 ? 0 : datagridPage - 1,
        pageSize: datagridPageSize,
    });

    const handlePaginationModelChange = (newPaginationModel) => {
        if (paginationModel.pageSize !== newPaginationModel.pageSize) {
            newPaginationModel.page = 0;
            setPaginationModel(newPaginationModel);
            changePageSize(newPaginationModel.pageSize);
        } else if (paginationModel.page !== newPaginationModel.page) {
            setPaginationModel(newPaginationModel);
            changePage(newPaginationModel.page + 1);
        }
    };

    return (
        <div style={{ height: "auto", width: "100%" }} className={"leads-list"}>
            <DataGrid
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
                pageSizeOptions={[10, 25, 50, 100]}
                rows={rows}
                columns={columns}
                rowCount={rowCount}
                loading={loadingDatagrid}
                disableColumnMenu
                disableSelectionOnClick={true}
                checkboxSelection={false}
                sx={datagridRegister}
            />
            <PopModalError />
        </div>
    );
};

/******/
/** Redux map state to props **/
const mapStateToProps = (state) => ({
    leadsObj: state?.leads,
    leadAssignment: state?.leadAssignment,
});

/** Redux map dispatch to props **/
const mapDispatchToProps = {
    getLeads,
    cleanLeadsAction,
    setLeadAssignmentAction,
    cleanLeadAssignmentAction,
};

/** Export component connected to redux **/
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShippingDetailDatagridComponent);
