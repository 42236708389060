// scrollbarStyles.js
export const getScrollbarStyles = () => ({
    "& *::-webkit-scrollbar": {
        width: "16px",
        height: "16px",
    },
    "& *::-webkit-scrollbar-track": {
        background: "#f0f0f0",
        borderRadius: "8px",
    },
    "& *::-webkit-scrollbar-thumb": {
        backgroundColor: "#aaa",
        borderRadius: "8px",
    },
    "& *::-webkit-scrollbar-thumb:hover": {
        backgroundColor: "#888",
    },
    overflow: "scroll",
});
