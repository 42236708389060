import {
    SIM_GIFT_ORDERS_ERROR,
    UPDATE_EMAIL,
    RESEND_EMAIL,
} from "_models/redux/types";
import axiosTokenInstance from "services/api/backoffice";

export const updateEmailAction =
    (transactionsId, email) => async (dispatch) => {
        const url = `/api/ipb/orders/sims/gifts/${transactionsId}/email`;

        try {
            const response = await axiosTokenInstance.put(url, { email });

            const { data, status } = response || {};

            dispatch({
                method: "put",
                payload: data || {},
                status: status || null,
                type: UPDATE_EMAIL,
            });
        } catch (e) {
            console.error(e);
            dispatch({
                method: "put",
                payload: e || {},
                status: e?.response?.status || null,
                type: SIM_GIFT_ORDERS_ERROR,
            });
            throw e;
        }
    };

export const resendEmailAction = (transactionsId) => async (dispatch) => {
    const url = `/api/ipb/orders/sims/gifts/${transactionsId}/send-email`;

    try {
        const response = await axiosTokenInstance.post(url, {});

        const { data, status } = response || {};

        dispatch({
            method: "post",
            payload: data || {},
            status: status || null,
            type: RESEND_EMAIL,
        });
    } catch (e) {
        console.error(e);
        dispatch({
            method: "post",
            payload: e || {},
            status: e?.response?.status || null,
            type: SIM_GIFT_ORDERS_ERROR,
        });
        throw e;
    }
};
