import React from "react";
import { Typography } from "@mui/material";
import DataTable from "widgets/datagrid/DataTable";

const DataTableSection = ({
    loading,
    mappedRows,
    Columns,
    page,
    size,
    pagination,
    handleRowClick,
}) => (
    <>
        {mappedRows?.length > 0 ? (
            <DataTable
                isActive={"isActive"}
                rows={mappedRows}
                columns={Columns}
                onRowClick={handleRowClick}
                page={page}
                pageSize={size}
                rowCount={pagination?.total_rows ?? 0}
                onPageChange={pagination?.handleChangePage}
                handleChangePageSize={pagination?.handleChangePageSize}
                loading={loading}
            />
        ) : (
            <Typography variant="h6">No hay registros disponibles</Typography>
        )}
    </>
);

export default DataTableSection;
