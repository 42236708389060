import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import CurrencyFormatter from "utils/helpers/CurrencyFormatter";

export const useListSimIpb = ({
    listSimIpbAction,
    listSimIpb,
    cleanListSimIpbClean,
    detailListSimIpbAction,
    detailListSimIpb,
    cleanDetailListSimIpbAction,
    transactionId,
    onClose,
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { search } = location;
    const queryParams = new URLSearchParams(search);

    const { payload } = listSimIpb || {};
    const { response } = detailListSimIpb || {};
    const { rows, pagination } = payload || {};

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const [loading, setLoading] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [page, setPage] = useState(0);
    const [size, setSize] = useState(50);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState(false);
    const [isDialogOpenDetails, setIsDialogOpenDetails] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [action, setAction] = useState(null);
    const [error, setError] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        const initialFilters = Object.fromEntries(queryParams.entries());
        setSearchParams(initialFilters);

        const initialPage = initialFilters.page
            ? parseInt(initialFilters.page, 10)
            : 0;
        const initialSize = initialFilters.size
            ? parseInt(initialFilters.size, 10)
            : 50;

        setPage(initialPage);
        setSize(initialSize);

        Object.keys(initialFilters).forEach((key) => {
            setValue(key, initialFilters[key]);
        });

        if (Object.keys(initialFilters).length > 0) {
            setLoading(true);
            listSimIpbAction(page, size, initialFilters)
                .then(() => setLoading(false))
                .catch(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (transactionId) {
            setIsDialogOpenDetails(true);
            detailListSimIpbAction(transactionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionId]);

    useEffect(() => {
        if (response?.response?.status > 200) {
            setDialogMessage("Error");
            setDialogMessage(response?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [response]);

    useEffect(() => {
        if (listSimIpb?.status === 200) {
            setLoading(false);
        } else if (listSimIpb?.payload?.response?.status > 200) {
            setDialogMessage(listSimIpb?.payload?.response?.data?.detail);
            setIsDialogOpen(true);
            setLoading(false);
        }
    }, [listSimIpb]);

    const onSubmit = (data) => {
        /* eslint-disable no-unused-vars */
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(
                ([_, value]) => value !== undefined && value !== ""
            )
        );
        /* eslint-enable no-unused-vars */

        const { from_date, to_date } = filteredData;
        if (from_date && to_date && new Date(from_date) > new Date(to_date)) {
            setErrorMessage(
                "La fecha de inicio no puede ser mayor a la fecha final"
            );
            return;
        }

        const queryString = new URLSearchParams(filteredData).toString();
        navigate(`/list/ipb?${queryString}`);

        setLoading(true);
        setPage(0);
        setSearchParams(filteredData);
        setIsSubmitted(true);
        setErrorMessage("");
    };

    useEffect(() => {
        if (isSubmitted || page !== 0 || size !== 50) {
            /* eslint-disable no-unused-vars */
            const filteredSearchParams = Object.fromEntries(
                Object.entries(searchParams).filter(
                    ([_, value]) => value !== undefined && value !== ""
                )
                /* eslint-enable no-unused-vars */
            );

            setLoading(true);
            listSimIpbAction(page, size, filteredSearchParams)
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    console.error("listSimIpbAction error:", error);
                    setLoading(false);
                });
        }
        return () => {
            cleanListSimIpbClean();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchParams,
        listSimIpbAction,
        cleanListSimIpbClean,
        isSubmitted,
        error,
    ]);

    useEffect(() => {
        if (isSubmitted || page !== 0 || size !== 50) {
            /* eslint-disable no-unused-vars */
            const filteredSearchParams = Object.fromEntries(
                Object.entries(searchParams).filter(
                    ([_, value]) => value !== undefined && value !== ""
                )
                /* eslint-enable no-unused-vars */
            );

            setLoading(true);
            listSimIpbAction(page, size, filteredSearchParams)
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    console.error("listSimIpbAction error:", error);
                    setLoading(false);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, size]);

    const handleChangePage = (newPage) => {
        setPage(newPage);

        const queryString = new URLSearchParams({
            ...searchParams,
            page: newPage,
        }).toString();
        navigate(`/list/ipb?${queryString}`);
    };

    const handleChangePageSize = (newSize) => {
        setSize(newSize);
        setPage(0);

        const queryString = new URLSearchParams({
            ...searchParams,
            size: newSize,
        }).toString();
        navigate(`/list/ipb?${queryString}`);
    };

    useEffect(() => {
        if (transactionId) {
            const filteredSearchParams = Object.fromEntries(
                Object.entries(searchParams).filter(
                    // eslint-disable-next-line no-unused-vars
                    ([_, value]) => value !== undefined && value !== ""
                )
            );
            setIsSubmitted(true);
            setLoading(true);
            setIsDialogOpenDetails(true);
            detailListSimIpbAction(transactionId);
            listSimIpbAction(page, size, filteredSearchParams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionId]);

    const mappedRows =
        rows?.map((row) => ({
            ...row,
            id: `${row.id}`,
        })) || [];

    const handleIdButtonClick = (id) => {
        if (id) {
            setIsDialogOpenDetails(true);
            detailListSimIpbAction(id)
                .then(() => setLoading(false))
                .catch(() => {
                    setDialogMessage("Error");
                    setDialogMessage(
                        "Error al cargar los detalles de la transferencia."
                    );
                    setIsDialogOpen(true);
                    setLoading(false);
                });

            return () => {
                cleanDetailListSimIpbAction();
            };
        }
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setIsDialogOpenDetails(false);
        cleanDetailListSimIpbAction();
        if (transactionId) {
            onClose();
        }
    };

    const handleOpenPopUp = () => {
        setAction(action);
    };

    const Columns = [
        {
            field: "id",
            headerName: "ID",
            width: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    onClick={() => handleIdButtonClick(params.row.id)}
                >
                    {"Ver detalle"}
                </Button>
            ),
        },
        {
            field: "amount",
            headerName: "Cantidad",
            width: 200,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => <CurrencyFormatter amount={params.value} />,
        },

        {
            field: "created",
            headerName: "Fecha DB",
            width: 250,
            headerAlign: "center",
            renderCell: (params) => {
                return convertToMexicoTime(params.row.created);
            },
        },

        {
            field: "email",
            headerName: "Email",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "name",
            headerName: "Nombre del cliente",
            width: 220,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "order_number",
            headerName: "Número de orden",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "product_name",
            headerName: "Nombre del producto",
            width: 200,
            align: "center",
            headerAlign: "center",
        },
    ];

    return {
        register,
        handleSubmit,
        errors,
        onSubmit,
        loading,
        page,
        size,
        handleChangePage,
        pagination: {
            ...pagination,
            handleChangePage,
            handleChangePageSize,
        },
        rows,
        mappedRows,
        Columns,
        isDialogOpen,
        setIsDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        setErrorMessage,
        setError,
        error,
        handleOpenPopUp,
    };
};
