import React from "react";
import { connect } from "react-redux";
import { Box, Typography, Grid, Button } from "@mui/material";
import FilterListing from "utils/filter-list/FilterListing";
import { UseListIpbHook } from "./hooks/UseListIpbHook";
import { ipbInvoiceListAction } from "_models/redux/ipb-invoice-list/action";
import { cleanIPBInvoiceListAction } from "_models/redux/ipb-invoice-list/clean-action";
import DataTable from "widgets/datagrid/DataTable";
import CustomDialog from "widgets/component/CustomDialog";
import { Link as RouterLink } from "react-router-dom";

const PageListIpb = ({
    ipbInvoiceListAction,
    cleanIPBInvoiceListAction,
    ipbInvoiceList,
}) => {
    const {
        register,
        handleSubmit,
        errors,
        onSubmit,
        handleCleanFilters,
        loading,
        page,
        size,
        pagination,
        mappedRows,
        handleRowClick,
        Columns,
        isDialogOpen,
        dialogMessage,
        handleClose,
        isDialogOpenDetails,
        errorMessage,
        fields,
    } = UseListIpbHook({
        ipbInvoiceListAction,
        cleanIPBInvoiceListAction,
        ipbInvoiceList,
    });

    return (
        <Box>
            <Grid container alignItems="center" justifyContent="space-between">
                <Grid item>
                    <h4>Listado de Facturación IPB</h4>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        component={RouterLink}
                        to="/facturar"
                    >
                        Nueva Factura
                    </Button>
                </Grid>
            </Grid>
            <Box sx={{ mb: 10 }}>
                <FilterListing
                    onSubmit={onSubmit}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    register={register}
                    onCleanFilters={handleCleanFilters}
                    fields={fields}
                />
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            </Box>

            {mappedRows?.length > 0 ? (
                <DataTable
                    rows={mappedRows}
                    columns={Columns}
                    onRowClick={handleRowClick}
                    page={page}
                    pageSize={size}
                    rowCount={pagination?.total_rows ?? 0}
                    onPageChange={pagination?.handleChangePage}
                    handleChangePageSize={pagination?.handleChangePageSize}
                    loading={loading}
                />
            ) : (
                <Typography variant="h6">
                    No hay registros disponibles
                </Typography>
            )}

            <CustomDialog
                fullWidth
                title={
                    ipbInvoiceList?.response?.payment_info === null
                        ? "No hay datos"
                        : "Transacción netpay"
                }
                // content={
                //     <DataTableDetailOrder
                //         type="detailsNetPay"
                //         handleOpenPopUp={handleOpenPopUp}
                //         setOpenActionFraud={setOpenActionFraud}
                //         response={ipbInvoiceList?.response}
                //     />
                // }
                open={isDialogOpenDetails}
                onClose={handleClose}
                maxWidth="md"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />

            <CustomDialog
                title="Mensaje"
                content={<p>{dialogMessage}</p>}
                open={isDialogOpen}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
                extraButtons={[
                    {
                        label: "Aceptar",
                        variant: "contained",
                        onClick: handleClose,
                        buttonColor: "purple",
                        textColor: "white",
                    },
                ]}
            />
        </Box>
    );
};

const mapStateToProps = (state) => ({
    ipbInvoiceList: state?.ipbInvoiceList,
});

const mapDispatchToProps = {
    ipbInvoiceListAction,
    cleanIPBInvoiceListAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageListIpb);
