import { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import dayjs from "dayjs";

import { GiftOrdersContext } from "../../data/giftOrdersContexts";

import { registerValidations } from "../../data/giftOrdersValidations";

export const useGiftOrdersHeaders = () => {
    // Obtener el contexto del módulo y sus propiedades
    const giftOrdersContext = useContext(GiftOrdersContext);

    // Desestructuración de las propiedades del contexto
    const { formProps, reduxProps, reducerProps } = giftOrdersContext || {};

    // Desestructuración de las propiedades del formulario
    const { handleSubmit, register, errors, reset, setValue } = formProps || {};

    // Desestructuración de las propiedades del Redux
    const { getGiftOrdersCleanAction } = reduxProps || {};

    // Desestructuración de las propiedades del Reducer
    const { giftOrdersReducer, dispatchGiftOrdersReducer } = reducerProps || {};
    const { fromDateValue, toDateValue } = giftOrdersReducer || {};

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        const phoneInput = queryParams.get("phoneInput") || "";
        const fromDate = queryParams.get("fromDate");
        const toDate = queryParams.get("toDate");

        const fromDateDayjs = fromDate ? dayjs(fromDate) : null;
        const toDateDayjs = toDate ? dayjs(toDate) : null;

        setValue("phoneInput", phoneInput);

        dispatchGiftOrdersReducer({
            property: "fromDateValue",
            value: fromDateDayjs,
        });

        dispatchGiftOrdersReducer({
            property: "toDateValue",
            value: toDateDayjs,
        });

        if (phoneInput || fromDate || toDate) {
            dispatchGiftOrdersReducer({
                property: "searchClicked",
                value: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // HEADERS Filters Methods:

    // Función que se ejecuta al enviar el formulario de búsqueda
    const onSubmit = (data) => {
        const { phoneInput } = data;

        const fromDate = fromDateValue
            ? fromDateValue.toISOString().split("T")[0]
            : "";
        const toDate = toDateValue
            ? toDateValue.toISOString().split("T")[0]
            : "";

        if (fromDate && toDate && new Date(fromDate) > new Date(toDate)) {
            dispatchGiftOrdersReducer({
                property: "errorModalMessage",
                value: "La fecha de inicio debe ser igual o estar antes de la fecha de fin",
            });
            return;
        }

        const queryString = new URLSearchParams({
            phoneInput,
            fromDate,
            toDate,
        }).toString();
        window.history.replaceState(
            null,
            "",
            `/sales/gift-orders?${queryString}`
        );

        dispatchGiftOrdersReducer({
            property: "searchClicked",
            value: true,
        });
    };

    // Función que se ejecuta al cambiar el valor del DatePicker (from or to / desde o hasta)
    const datePickerOnChange = (value, type) => {
        const datePicker = type === "from" ? "fromDateValue" : "toDateValue";

        dispatchGiftOrdersReducer({
            property: datePicker,
            value: value,
        });
    };

    const inputOnChange = () => {};

    // Función que limpia los valores de los datepickers
    const cleanDatePickers = () => {
        dispatchGiftOrdersReducer({
            property: "fromDateValue",
            value: null,
        });

        dispatchGiftOrdersReducer({
            property: "toDateValue",
            value: null,
        });
    };

    // Función que limpia los valores del formulario y el estado del redux
    const cleanAll = () => {
        cleanDatePickers();

        reset({
            phoneInput: "",
        });

        getGiftOrdersCleanAction();
    };

    // Propiedades / variables / estados y métodos / funciones que se usarán en los componentes
    return {
        // Formulario:
        handleSubmit,
        register,
        errors,

        // Métodos:
        onSubmit,
        cleanAll,
        cleanDatePickers,

        // Eventos:
        datePickerOnChange,
        inputOnChange,

        // Estados:
        fromDateValue,
        toDateValue,

        // Validaciones:
        registerValidations,
    };
};
