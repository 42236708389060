import React from "react";
import CustomDialog from "widgets/component/CustomDialog";
import DataTableDetailOrder from "widgets/customDialogComponentDetails/ComponentDialog";
const CustomDialogComponent = ({
    modalOpenDetailOrder,
    saleOrderDetail,
    handleClose,
}) => (
    <CustomDialog
        fullWidth
        title={"Detalle Orden de Compra"}
        content={
            saleOrderDetail?.payload?.purchase === null ? (
                "No hay datos"
            ) : (
                <DataTableDetailOrder
                    type="detailPurchase"
                    handleOpenPopUp={modalOpenDetailOrder}
                    response={saleOrderDetail?.payload}
                    idTransaction={
                        saleOrderDetail?.payload?.payment_provider
                            ?.transaction_id
                    }
                    namePayment={
                        saleOrderDetail?.payload?.payment_provider?.name
                    }
                    onClose={handleClose}
                />
            )
        }
        open={modalOpenDetailOrder}
        onClose={handleClose}
        maxWidth="md"
        extraButtons={[
            {
                label: "Aceptar",
                variant: "contained",
                onClick: handleClose,
                buttonColor: "purple",
                textColor: "white",
            },
        ]}
    />
);
export default CustomDialogComponent;
