import React from "react";
import Button from "@mui/material/Button";
import "moment/locale/es";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CustomTextField } from "utils/components/fields/CustomTextField/CustomTextField";

const FilterForm = ({
    handleSubmit,
    onSubmit,
    dateValue,
    setDateValue,
    register,
    registerOptions,
    inputChange,
    errors,
}) => (
    <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
            container
            spacing={3}
            sx={{
                width: "calc(100vw - 270px)",
            }}
        >
            <Grid item xs={6} md={2} className={"filter-container"}>
                <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    adapterLocale={"es"}
                >
                    <DatePicker
                        label="dd/mm/yyyy"
                        value={dateValue}
                        onChange={(newValue) => setDateValue(newValue)}
                    />
                </LocalizationProvider>
            </Grid>
            <CustomTextField
                id="numberOrder"
                label="No. de orden"
                placeholder="No. de orden"
                register={register}
                registerOptions={registerOptions?.numberOrder}
                onChange={(e) => inputChange(e)}
                errors={errors?.numberOrder}
            />
            <CustomTextField
                id="nameOrder"
                label="Nombre"
                placeholder="Nombre"
                register={register}
                registerOptions={registerOptions?.nameOrder}
                onChange={(e) => inputChange(e)}
                errors={errors?.nameOrder}
            />
            <CustomTextField
                id="phoneNumberOrder"
                label="Teléfono"
                placeholder="Teléfono"
                register={register}
                registerOptions={registerOptions?.phoneNumberOrder}
                onChange={(e) => inputChange(e)}
                errors={errors?.phoneNumberOrder}
            />
            <CustomTextField
                id="emailOrder"
                label="Correo electrónico"
                placeholder="Correo electrónico"
                register={register}
                registerOptions={registerOptions?.emailOrder}
                onChange={(e) => inputChange(e)}
                errors={errors?.emailOrder}
            />
            <Grid item xs={2}>
                <Button
                    variant="contained"
                    className="m-0 h-55 small-button"
                    type="submit"
                >
                    <SearchIcon className="svg" />
                </Button>
            </Grid>
        </Grid>
    </form>
);
export default FilterForm;
