import {
    SIM_GIFT_ORDERS,
    SIM_GIFT_ORDERS_ERROR,
    UPDATE_EMAIL,
    RESEND_EMAIL,
} from "_models/redux/types";

const initialState = {
    orders: [],
    total: 0,
    page: 1,
    limit: 10,
    loading: false,
    error: null,
};

const simGiftOrdersReducer = (state = initialState, action) => {
    switch (action.type) {
        case SIM_GIFT_ORDERS:
            return {
                ...state,
                orders: action.payload.orders || [],
                total: action.payload.total || 0,
                page: action.payload.page || 1,
                limit: action.payload.limit || 10,
                loading: false,
                error: null,
            };
        case UPDATE_EMAIL:
        case RESEND_EMAIL:
            return {
                ...state,
                loading: false,
                error: null,
            };
        case SIM_GIFT_ORDERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default simGiftOrdersReducer;
