import { convertToMexicoTime } from "utils/helpers/convertToMexicoTime";
import CurrencyFormatter from "utils/helpers/CurrencyFormatter";

export const getAmountDetails = (payment) => [
    {
        label: "Revisión requerida",
        value: payment?.review_required ? "Sí" : "No",
    },
    {
        label: "Es contracargo",
        value: payment?.is_chargeback ? "Sí" : "No",
    },
    {
        label: "Es fraude",
        value: payment?.is_fraud ? "Sí" : "No",
    },
];

export const getAuthorizationDetails = (payment) => [
    {
        label: "Número de transacción",
        value: payment?.transaction_number,
    },
    {
        label: "Código de respuesta",
        value: payment?.response_code,
    },
    {
        label: "Transacción ID",
        value: payment?.openpay_transaction_id,
    },
    {
        label: "Código de autorización",
        value: payment?.authorization_code,
    },
    {
        label: "ID Pago",
        value: payment?.uuid,
    },
];

export const getBankDetails = (payment) => [
    {
        label: "Nombre del banco",
        value: payment?.bank_name,
    },
    {
        label: "Tarjeta enmascarada",
        value: payment?.masked_card,
    },
];

export const getTransactionDetails = (transaction) => [
    {
        label: "Fecha de transacción",
        value: convertToMexicoTime(transaction?.transaction_time),
    },
    {
        label: "Fecha de aplicación",
        value: convertToMexicoTime(transaction?.created),
    },
    {
        label: "Monto",
        value: <CurrencyFormatter amount={transaction?.amount} />,
    },
    {
        label: "Nombre del banco",
        value: transaction?.bank_name,
    },
    {
        label: "Tarjeta enmascarada",
        value: transaction?.masked_card,
    },
    {
        label: "Estado del pago",
        value: transaction?.payment_status,
    },
    {
        label: "ID del estado del pago",
        value: transaction?.payment_status_id,
    },
    {
        label: "Mensualidades",
        value:
            transaction?.installments === 1
                ? "Contado"
                : `${transaction?.installments} meses`,
    },
];

export const getPaymentStatusDetails = (payment) => [
    {
        label: "Estado del pago",
        value: payment?.payment_status,
    },
    {
        label: "ID del estado del pago",
        value: payment?.payment_status_id,
    },
    {
        label: "Revisión requerida",
        value: payment?.review_required ? "Sí" : "No",
    },
    {
        label: "Es contracargo",
        value: payment?.is_chargeback ? "Sí" : "No",
    },
    {
        label: "Es fraude",
        value: payment?.is_fraud ? "Sí" : "No",
    },
];
