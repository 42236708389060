import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { LoadersContext } from "_models/context/app";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { LoadingButton } from "@mui/lab";
import SimTypeChip from "utils/SimTypeChip";
import ActionLabels from "utils/ActionLabelsDatagrid";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";

export const DataGridHook = ({
    getSaleOrdersAction,
    saleOrders,
    cleanSaleOrdersAction,
    getSaleOrderDetailAction,
    cleanSaleOrderDetailAction,
    saleOrderDetail,
    transactionId,
    onClose,
}) => {
    const loadersContext = useContext(LoadersContext);

    const {
        getValues,
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        defaultValues: {},
    });

    const location = useLocation();
    const navigate = useNavigate();
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    const initialPage = queryParams.get("page")
        ? parseInt(queryParams.get("page"), 10)
        : 0;
    const initialSize = queryParams.get("size")
        ? parseInt(queryParams.get("size"), 10)
        : 50;

    const [datagridPageSize, setDatagridPageSize] = useState(initialSize);
    const [datagridPage, setDatagridPage] = useState(initialPage);

    const { setIsScreenLoading } = loadersContext || {};

    const { payload } = saleOrders || {};
    const { result, pagination } = payload || {};

    const [dateValue, setDateValue] = useState(null);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [loadingDatagrid, setLoadingDatagrid] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalOpenDetailOrder, setModalOpenDetailOrder] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const [frontErrorMessage, setFrontErrorMessage] = useState("");

    useEffect(() => {
        const initialFilters = Object.fromEntries(queryParams.entries());

        if (initialFilters.number_order) {
            setValue("numberOrder", initialFilters.number_order);
        }
        if (initialFilters.name_order) {
            setValue("nameOrder", initialFilters.name_order);
        }
        if (initialFilters.phone_number_order) {
            setValue("phoneNumberOrder", initialFilters.phone_number_order);
        }
        if (initialFilters.email_order) {
            setValue("emailOrder", initialFilters.email_order);
        }
        if (initialFilters.date_order) {
            setDateValue(dayjs(initialFilters.date_order));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        if (transactionId) {
            setModalOpenDetailOrder(true);
            getSaleOrderDetailAction(transactionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionId]);

    useEffect(() => {
        generateDataColumns();

        return () => {
            cleanSaleOrdersAction();
            setIsScreenLoading(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (dateValue) {
            moment(dateValue.format("YYYY-MM-DD")).format();
        }
    }, [dateValue]);

    useEffect(() => {
        if (
            saleOrders?.state === "error" &&
            (saleOrders?.status === null || saleOrders?.status >= 300)
        ) {
            setOpen(true);
        } else {
            setOpen(false);
        }
        setLoadingDatagrid(false);
    }, [saleOrders]);

    useEffect(() => {
        generateDataRows();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result]);

    useEffect(() => {
        const { state, status } = saleOrderDetail || {};

        if (state === "success" && (status >= 200 || status < 300)) {
            setOpen(false);
            setModalOpenDetailOrder(true);
        } else if (state === "error" && (status >= 300 || status === null)) {
            setOpen(true);
        } else {
            setOpen(false);
        }

        setIsScreenLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saleOrderDetail, transactionId]);

    useEffect(() => {
        if (!open) {
            setFrontErrorMessage();
        }
    }, [open]);

    useEffect(() => {
        if (datagridPage === 0) {
            return;
        }

        if (datagridPage !== 1) {
            getSaleOrdersMethod("page", datagridPage);
        } else {
            getSaleOrdersMethod("search", datagridPage);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datagridPage, datagridPageSize, dateValue]);

    const changeOrderPage = (newPage, newPageSize) => {
        if (newPageSize !== datagridPageSize) {
            setDatagridPageSize(newPageSize);
            setDatagridPage(1);
        } else {
            setDatagridPage(newPage);
        }
    };

    const handleActionOrderDetail = (props) => {
        getSaleOrderDetailAction(props);
        setIsScreenLoading(true);
    };

    const generateDataColumns = () => {
        const leadsColumns = [
            {
                field: "id",
                headerName: "Número de orden",
                width: 220,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { order_id, bg } = row || {};
                    return (
                        <div style={{ backgroundColor: bg }}>
                            <LoadingButton
                                variant="outlined"
                                size="small"
                                onClick={() =>
                                    handleActionOrderDetail(order_id)
                                }
                                sx={{
                                    ":hover": { color: "white" },
                                    width: "200",
                                }}
                            >
                                {"Ver detalle"}
                            </LoadingButton>
                        </div>
                    );
                },
            },
            {
                field: "prevention",
                headerName: "Prevención",
                width: 200,
                headerAlign: "center",
                renderCell: (params) => (
                    <ActionLabels
                        isFraud={params.row.is_fraud}
                        isChargeback={params.row.is_chargeback}
                    />
                ),
            },
            {
                field: "sim_type",
                headerName: "Tipo de SIM",
                width: 310,
                renderCell: (params) => {
                    if (!params.value) {
                        return null;
                    }
                    return <SimTypeChip simTypeName={params.value} />;
                },
            },
            {
                field: "date",
                headerName: "Fecha de compra",
                width: 310,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { date, bg, color } = row || {};
                    return (
                        <div style={{ backgroundColor: bg, color: color }}>
                            {date}
                        </div>
                    );
                },
            },
            {
                field: "name_order",
                headerName: "Nombre",
                width: 310,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { name_order, bg, color } = row || {};
                    return (
                        <div style={{ backgroundColor: bg, color: color }}>
                            {name_order}
                        </div>
                    );
                },
            },
            {
                field: "contact_number_order",
                headerName: "Teléfono",
                width: 310,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { contact_number_order, bg, color } = row || {};
                    return (
                        <div style={{ backgroundColor: bg, color: color }}>
                            {contact_number_order}
                        </div>
                    );
                },
            },
            {
                field: "email_order",
                headerName: "Correo electrónico",
                width: 310,
                renderCell: (params) => {
                    const { row } = params || {};
                    const { email_order, bg, color } = row || {};
                    return (
                        <div style={{ backgroundColor: bg, color: color }}>
                            {email_order}
                        </div>
                    );
                },
            },
        ];

        setColumns(leadsColumns);
    };

    const generateDataRows = () => {
        let ordersRow = [];

        if (result) {
            ordersRow = result.map((element) => {
                return {
                    id: element?.order_number,
                    date: element?.created ? element.created.split("T")[0] : "",
                    name_order: element?.name,
                    contact_number_order: element?.contact_phone,
                    email_order: element?.email,
                    order_id: element?.order_id,
                    sim_type: element?.sim_type,
                    is_chargeback: element?.is_chargeback,
                    is_fraud: element?.is_fraud,
                };
            });
            setRowCount(pagination.total_rows);
        }

        setRows(ordersRow);
    };

    const getSaleOrdersMethod = (from, page) => {
        setLoadingDatagrid(true);

        const filtersValues = getValues();

        const { numberOrder, nameOrder, phoneNumberOrder, emailOrder } =
            filtersValues || {};

        if (
            numberOrder === "" &&
            nameOrder === "" &&
            phoneNumberOrder === "" &&
            emailOrder === "" &&
            (dateValue === "" || dateValue === null || dateValue === undefined)
        ) {
            setFrontErrorMessage(<p>Debe ingresar al menos un filtro.</p>);
            setLoadingDatagrid(false);
            setOpen(true);
        } else {
            let formatDateValue = "";

            if (dateValue) {
                formatDateValue = moment(
                    dateValue.format("YYYY-MM-DD")
                ).format();
            }

            let dateFormatted = "";
            if (formatDateValue.split("T")[0]) {
                dateFormatted = formatDateValue.split("T")[0];
            }

            if (from === "search") {
                page = 1;
            }

            const getSalesOrdersParams = {
                page: page,
                size: datagridPageSize,
                number_order: numberOrder,
                name_order: nameOrder,
                phone_number_order: phoneNumberOrder,
                email_order: emailOrder,
                date_order: dateFormatted,
            };

            const filteredParams = Object.entries(getSalesOrdersParams).reduce(
                (acc, [key, value]) => {
                    if (value !== null && value !== undefined && value !== "") {
                        acc[key] = value;
                    }
                    return acc;
                },
                {}
            );

            const queryString = new URLSearchParams(filteredParams).toString();
            navigate(`/sales/orders?${queryString}`);

            localStorage.setItem(
                "getSalesOrdersParams",
                JSON.stringify(getSalesOrdersParams)
            );

            getSaleOrdersAction(getSalesOrdersParams);
        }
    };

    const onSubmit = () => {
        getSaleOrdersMethod("search");
    };

    const inputChange = (e) => {
        const { id, value } = e.target;
        setValue(id, value);
    };

    const registerOptions = {
        numberOrder: {
            // required: "Número de orden ",
            minLength: {
                value: 3,
                message: "Mínimo 3 caracteres",
            },
            maxLength: {
                value: 150,
                message: "Máximo 150 caracteres",
            },
        },
        nameOrder: {
            // required: "Nombre es requerido",
            minLength: {
                value: 3,
                message: "Mínimo 3 caracteres",
            },
            maxLength: {
                value: 150,
                message: "Máximo 150 caracteres",
            },
        },

        phoneNumberOrder: {
            // required: "Número telefónico es requerido",
            pattern: {
                value: /^(0|[0-9]\d*)(\.\d+)?$/,
                message: "El formato telefónico no es correcto",
            },
        },

        emailOrder: {
            // required: "El correo electrónico es requerido",
            pattern: {
                value: /^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/,
                message:
                    "El valor ingresado no coincide con el formato del correo electrónico",
            },
        },
    };
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        "& .MuiDialogContent-root": {
            padding: theme.spacing(2),
        },
        "& .MuiDialogActions-root": {
            padding: theme.spacing(1),
        },
    }));

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                {children}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </DialogTitle>
        );
    };
    const handleClose = () => {
        setOpen(false);
        setIsScreenLoading(false);
        setModalOpenDetailOrder(false);
        if (transactionId) {
            onClose();
        }
        cleanSaleOrderDetailAction();
    };

    const PopModalError = () => {
        return (
            <div>
                <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle
                        id="customized-dialog-title"
                        onClose={handleClose}
                    >
                        Error
                    </BootstrapDialogTitle>
                    <DialogContent dividers>
                        <Typography gutterBottom>
                            {"---"}
                            {frontErrorMessage}
                            {saleOrders?.payload?.code ||
                            saleOrders?.payload?.response?.data?.detail ||
                            saleOrderDetail?.payload?.response?.data?.detail ||
                            saleOrderDetail?.payload?.code ? (
                                <>
                                    <p>
                                        {
                                            saleOrders?.payload?.response?.data
                                                ?.detail
                                        }
                                    </p>
                                    <p>
                                        {saleOrders?.payload?.message
                                            ? "[" +
                                              saleOrders?.payload?.message +
                                              "]"
                                            : ""}
                                    </p>
                                    <p>
                                        {
                                            saleOrderDetail?.payload?.response
                                                ?.data?.detail
                                        }
                                    </p>
                                    <p>
                                        {saleOrderDetail?.payload?.message
                                            ? "[" +
                                              saleOrderDetail?.payload
                                                  ?.message +
                                              "]"
                                            : ""}
                                    </p>
                                </>
                            ) : null}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cerrar</Button>
                    </DialogActions>
                </BootstrapDialog>
            </div>
        );
    };

    const getRowClassName = (params) => {
        if (params.row.is_chargeback && params.row.is_fraud) {
            return "chargeback-and-fraud-row";
        } else if (params.row.is_chargeback) {
            return "chargeback-row";
        } else if (params.row.is_fraud) {
            return "fraud-row";
        }
        return "";
    };

    // const handleOpenPopUp = (action, rowId) => {
    //     setAction(action);
    //     setSelectedRowId(rowId);
    //     setOpenActionFraud(true);
    // };

    return {
        columns,
        rows,
        datagridPageSize,
        rowCount,
        datagridPage,
        loadingDatagrid,
        modalOpenDetailOrder,
        onSubmit,
        registerOptions,
        PopModalError,
        handleSubmit,
        dateValue,
        setDateValue,
        register,
        errors,
        changeOrderPage,
        setModalOpenDetailOrder,
        getRowClassName,
        handleClose,
        inputChange,
    };
};
